/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 205
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/veryableops/Marketplace/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: 'ba1cc93ac0b9f03e610314a3a0682a85',
  development: '4102652cf27afc25bdf232bc9e97eec7'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '205',
    branch: 'main',
    source: 'web',
    versionId: '9f6bf4ff-02e0-4208-b22f-b734101667fe'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * The business' ID
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Business Id"?: number;
  /**
   * The district of the user.
   */
  District?: string;
  /**
   * Email
   */
  Email?: string;
  /**
   * User First Name
   */
  "First Name"?: string;
  /**
   * The internal user's district.
   */
  "Internal District"?: string;
  /**
   * When an Operator is cleared to work
   */
  "Is Cleared To Work"?: boolean;
  "Is Forklift Academy Certified"?: boolean;
  /**
   * Indicates if the user is either internal of business user.
   */
  "Is Internal User"?: boolean;
  "Is OSHA Certified"?: boolean;
  /**
   * When an Operator is a Spanish speaker
   */
  "Is Spanish Speaking"?: boolean;
  /**
   * When an Operator is a student
   */
  "Is Student"?: boolean;
  /**
   * When an Operator is suspended
   */
  "Is Suspended"?: boolean;
  /**
   * When an Operator is a veteran
   */
  "Is Veteran"?: boolean;
  /**
   * User Last Name
   */
  "Last Name"?: string;
  /**
   * A business user's locations
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  Locations?: string[];
  /**
   * Operator ID
   */
  "Operator Id"?: string;
  /**
   * An Operator's reliability for Ops
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Operator Reliability"?: number;
  /**
   * Amount of Ops completed by an Operator
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Ops Completed"?: number;
  /**
   * An Operator's overall rating
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Overall Rating"?: number;
  /**
   * Role of user.
   */
  "Role Name"?: string;
  /**
   * All the roles associated with a user
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  Roles?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "User Id"?: number;
  /**
   * Business or Operator
   */
  "User Type"?: string;
  /**
   * A business user's work areas.
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Work Areas"?: string[];
}

export interface AdjustHoursButtonClickedProperties {
  "Clicked Via": string;
  "Op Day Id": string;
  /**
   * Op ID
   */
  "Op Id": string;
  /**
   * Operator ID
   */
  "Operator Id": string;
}

export interface AdjustToPostedHoursButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Calculated Hours"?: number;
  "Op Day Id": string;
  /**
   * Op ID
   */
  "Op Id": string;
  /**
   * Operator ID
   */
  "Operator Id": string;
  "Original Qty": string;
}

export interface AllOperatorsTabFilteredProperties {
  /**
   * multi-select
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  "Badges Filter"?: string[];
  "Certifications Filter"?: string;
  "Distance Filter"?: string;
  "Options Filter"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Ratings Filter"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Reliability Filter"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Skills Filter"?: string[];
}

export interface BannerOnboardingActionButtonClickedProperties {
  "Banner Button Name": string;
}

export interface BannerOnboardingEnabledProperties {
  /**
   * Business ID
   */
  "Business Id"?: string;
  "Is Enabled": boolean;
}

export interface BidAcceptedProperties {
  /**
   * Delta between time of acceptance and Op earliest start time
   *
   * (Op Earliest Start time - Accepted time) (in hours)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Accepted Lead Time"?: number;
  /**
   * Source- email, autofill, Op details
   */
  "Accepted Via"?: string;
  /**
   * Bid ID
   */
  "Bid Id": string;
  "Is From Notification"?: boolean;
  /**
   * Does the Operator have an Endorsement
   */
  "Is Operator Endorsed"?: boolean;
  /**
   * Has the operator been endorsed by the accepting business
   */
  "Is Operator Endorsed by Business"?: boolean;
  /**
   * Op ID
   */
  "Op Id": string;
  /**
   * Operator ID
   */
  "Operator Id": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Operator Ops Completed": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Operator Rating"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Operator Reliability"?: number;
}

export interface BidsFilteredProperties {
  /**
   * multi-select
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  "Badges Filter"?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Distance Filter"?: number;
  /**
   * Op ID
   */
  "Op Id": string;
  "Ratings Filter"?: string;
  "Reliability Filter"?: string;
  "Skills Filter"?: string;
  /**
   * Ylp type and tags
   */
  "Ylp Filter"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Ylp Tags"?: string[];
}

export interface BillingContactAddedProperties {
  "Contact Added Via": string;
}

export interface BreadcrumbClickedProperties {
  "Clicked Back To": string;
}

export interface BulkBackupSkippedProperties {
  /**
   * Op ID
   */
  "Op Id": string;
}

export interface BulkPostOpEditedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "New Total Ops Added": number;
  "Op Type": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Previous Total Ops Added": number;
}

export interface BulkPostSubmittedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Total Ops Posted"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Total Ops Scheduled"?: number;
}

export interface BusinessPortalEnteredProperties {
  /**
   * Business ID
   */
  "Business Id"?: string;
  "Business Name": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  Locations: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "User Role": string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Work Areas": string[];
}

export interface BusinessSelectedProperties {
  /**
   * Business ID
   */
  "Business Id"?: string;
  "Business Name": string;
  District?: string;
  /**
   * Business Selected Event- is this a User that has access to multiple portals
   */
  "Is Multi Portal Business"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  Locations: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "User Role": string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Work Areas": string[];
}

export interface CancelOpClickedProperties {
  /**
   * Op ID
   */
  "Op Id": string;
}

export interface CancelOpSummaryClickedProperties {
  /**
   * Op ID
   */
  "Op Id": string;
}

export interface CheckInButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Click Lead Time": number;
  "Clicked Via"?: string;
  "Is Background Location Enabled"?: boolean;
  "Is Code Required"?: boolean;
  "Is Foreground Location Enabled"?: boolean;
  "Is Noahface Business"?: boolean;
  "Is Precise Location Enabled"?: boolean;
  "Is Within Geofence"?: boolean;
  "Op Day Id": string;
  /**
   * Op ID
   */
  "Op Id": string;
  /**
   * Operator ID
   */
  "Operator Id": string;
}

export interface CheckOutButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Click Lead Time": number;
  "Clicked Via"?: string;
  "Is Background Location Enabled"?: boolean;
  "Is Code Required"?: boolean;
  "Is Foreground Location Enabled"?: boolean;
  "Is Noahface Business"?: boolean;
  "Is Precise Location Enabled"?: boolean;
  "Is Within Geofence"?: boolean;
  "Op Day Id": string;
  /**
   * Op ID
   */
  "Op Id": string;
  /**
   * Operator ID
   */
  "Operator Id": string;
}

export interface CreateProfileSourceProperties {
  /**
   * Business ID
   */
  "Business Id"?: string;
  "Business Name": string;
  /**
   * Property to track the business referral comment if they select 'Other'
   */
  "Business Referral Comment"?: string;
  /**
   * The dropdown value for the business referral source
   */
  "Business Referral Source": string;
  District?: string;
}

export interface DisputeReversedProperties {
  /**
   * Assignment ID
   */
  "Assignment Id": string;
  "Op Day Id": string;
  /**
   * Op ID
   */
  "Op Id": string;
}

export interface DoNotPayButtonClickedProperties {
  "Clicked Via": string;
  "Has Timestamps": boolean;
  "Op Day Id": string;
  /**
   * Op ID
   */
  "Op Id": string;
  /**
   * Operator ID
   */
  "Operator Id": string;
}

export interface DocumentDeletedProperties {
  "Document Name": string;
}

export interface DocumentUploadedProperties {
  "Document Name": string;
}

export interface GeofenceCreatedProperties {
  /**
   * Geofence size (in feet)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Geofence Radius Size"?: number;
  "Geofence Type": string;
}

export interface GeofenceMapClearedProperties {
  "Geofence Type": string;
}

export interface GeofencePreviewedProperties {
  "Geofence Type": string;
  "Is Primary": boolean;
}

export interface GeofenceUpdatedProperties {
  /**
   * Geofence size (in feet)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Geofence Radius Size"?: number;
  "Geofence Type": string;
}

export interface HvdAdjustmentsFilteredProperties {
  "End Date"?: string;
  "Filtered Via": string;
  Location?: string;
  /**
   * Op ID
   */
  "Op Id"?: string;
  "Start Date"?: string;
  "Work Area Name"?: string;
}

export interface HvdOpsFilteredProperties {
  "End Date"?: string;
  "Filtered Via": string;
  "First Name"?: string;
  "Last Name"?: string;
  Location?: string;
  /**
   * Op ID
   */
  "Op Id"?: string;
  "Start Date"?: string;
  "Work Area Name"?: string;
}

export interface HvdRatingsFilteredProperties {
  "End Date"?: string;
  "First Name"?: string;
  "Last Name"?: string;
  Location?: string;
  /**
   * Op ID
   */
  "Op Id"?: string;
  "Start Date"?: string;
  "Work Area Name"?: string;
}

export interface IndustryGatePassedProperties {
  Industry: string;
  "Industry Sector": string;
  "Type Of Operation": string;
}

export interface InlineInButtonClickedProperties {
  "Clicked Via": string;
  "Op Day Id": string;
  "Op Day Status": string;
  /**
   * Op ID
   */
  "Op Id": string;
  /**
   * Operator ID
   */
  "Operator Id": string;
}

export interface InlineOutButtonClickedProperties {
  "Clicked Via": string;
  "Op Day Id": string;
  "Op Day Status": string;
  /**
   * Op ID
   */
  "Op Id": string;
  /**
   * Operator ID
   */
  "Operator Id": string;
}

export interface LaborPoolFilteredProperties {
  "Labor Pool Tags Filter"?: string;
  "Options Filter"?: string;
  "Ratings Filter"?: string;
  "Reliability Filter"?: string;
  "Skills Filter"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Work Area Filter"?: string[];
}

export interface LinkAddedProperties {
  "Added Via"?: string;
  "Link Name": string;
}

export interface LinkDeletedProperties {
  "Link Name": string;
}

export interface LinkEditedProperties {
  "Link Name": string;
  "Previous Link Name": string;
}

export interface LocationAddedProperties {
  /**
   * Geofence size (in feet)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Geofence Radius Size"?: number;
  "Geofence Type": string;
  "Is Primary": boolean;
}

export interface LocationDeletedProperties {
  "Location Name": string;
}

export interface LocationFlowExitedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Location Flow Step Number": number;
}

export interface LocationUpdatedProperties {
  /**
   * Geofence size (in feet)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Geofence Radius Size"?: number;
  "Geofence Type": string;
  "Is Primary": boolean;
  "Name Updated": boolean;
}

export interface LoggedInProperties {
  "Is Multi Portal Business User": boolean;
  "Logged In With Google"?: boolean;
}

export interface LogoUpdatedProperties {
  "Logo Type": string;
}

export interface OpCodesSidebarViewedProperties {
  "Is Check In Code Required"?: boolean;
  "Is Check Out Code Required"?: boolean;
  /**
   * Op ID
   */
  "Op Id": string;
}

export interface OpContactAddedProperties {
  /**
   * Business Info Page, Long Post, Edit Template
   */
  "Added Via"?: string;
}

export interface OpDayAdjustedProperties {
  /**
   * Adjusted payout amount
   */
  "Adjusted Amount"?: string;
  /**
   * Adjusted amount of hours or units
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "Adjusted Qty"?: string;
  "Adjustment Reason"?: string;
  /**
   * Assignment ID
   */
  "Assignment Id": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Calculated Hours"?: number;
  "Is Flexible Arrival": boolean;
  "Is From Notification"?: boolean;
  "Op Day Id": string;
  /**
   * Op ID
   */
  "Op Id": string;
  "Original Amount": string;
  "Original Qty": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Units Per Hour"?: number;
}

export interface OpDayCancelledProperties {
  /**
   * Assignment ID
   */
  "Assignment Id": string;
  Comment: string;
  "Has Timestamps"?: boolean;
  "Is From Notification"?: boolean;
  /**
   * Was the Op Day Cancelled within 12 hours of the start time?
   */
  "Is Late Cancellation"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Number Of Days Cancelled": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Number Of Days Remaining": number;
  "Op Day Id": string;
  /**
   * Op ID
   */
  "Op Id": string;
  /**
   * Operator ID
   */
  "Operator Id": string;
}

export interface OpDayDisputedProperties {
  /**
   * Assignment ID
   */
  "Assignment Id": string;
  "Dispute Category": string;
  "Dispute Comment"?: string;
  "Has Timestamps"?: boolean;
  "Is Blocked": boolean;
  "Is From Notification"?: boolean;
  "Op Day Id": string;
  /**
   * Op ID
   */
  "Op Id": string;
}

export interface OpDayMarkedAbsentProperties {
  /**
   * Assignment ID
   */
  "Assignment Id": string;
  "Is From Notification"?: boolean;
  "Op Day Id": string;
  /**
   * Op ID
   */
  "Op Id": string;
}

export interface OpDeactivatedProperties {
  /**
   * Op ID
   */
  "Op Id": string;
}

export interface OpDetailsViewedProperties {
  "Viewed Via": string;
}

export interface OpEditedProperties {
  "Keep Bids": boolean;
  /**
   * Op ID
   */
  "Op Id": string;
}

export interface OpInfoSidebarViewedProperties {
  "Viewed Via": string;
}

export interface OpPostedProperties {
  /**
   * Op ID
   */
  "Op Id": string;
}

export interface OpReactivatedProperties {
  /**
   * Op ID
   */
  "Op Id": string;
}

export interface OpScheduledProperties {
  "Scheduled Op Id"?: string;
  "Scheduled To Post On": string;
  "Scheduled Via": string;
  /**
   * Number of hours from scheduling time until Op posting time
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Scheduling Lead Time": number;
}

export interface OpTemplateCreatedProperties {
  Attachments?: string;
  "Has Attachments": boolean;
  "Template Created Via": string;
  "Work Area Name"?: string;
}

export interface OpTemplateEditedProperties {
  Attachments?: string;
  "Auto Accept Invited Edited": boolean;
  "Autofill Edited": boolean;
  "Has Attachments": boolean;
  "Is Auto Accept Invited": boolean;
  "Is Autofill": boolean;
  "Is Ylp Only": boolean;
  "Skill Edited": boolean;
  "Skill New Value": string;
  "Skill Previous Value": string;
  "Template Options Edited": boolean;
  "Work Area Edited": boolean;
  "Work Area New Value": string;
  "Work Area Previous Value": string;
  "Ylp Only Edited": boolean;
}

export interface OperatorAddedToBackupProperties {
  "Added Via"?: string;
  /**
   * Bid ID
   */
  "Bid Id": string;
  "Is From Notification"?: boolean;
  /**
   * Op ID
   */
  "Op Id": string;
  /**
   * Operator ID
   */
  "Operator Id": string;
}

export interface OperatorAddedToLaborPoolProperties {
  "Added To Labor Pool Via": string;
  /**
   * Business ID
   */
  "Business Id"?: string;
  "Last Active"?: string;
  "Last Bid"?: string;
  "Last Worked"?: string;
  /**
   * Operator ID
   */
  "Operator Id": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Operator Rating"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Operator Reliability"?: number;
  "Was Previously Removed"?: boolean;
}

export interface OperatorBlockedProperties {
  "Blocked Via": string;
  /**
   * Business ID
   */
  "Business Id"?: string;
  /**
   * Operator ID
   */
  "Operator Id": string;
}

export interface OperatorEndorsedProperties {
  "Endorsed Via": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Endorsement Type": string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Endorsements Received": string[];
  /**
   * Operator ID
   */
  "Operator Id": string;
}

export interface OperatorInvitedToOpProperties {
  "Operator Invited Via": string;
}

export interface OperatorRatedProperties {
  /**
   * Assignment ID
   */
  "Assignment Id": string;
  "Is Favorite": boolean;
  /**
   * Op ID
   */
  "Op Id": string;
  /**
   * Operator ID
   */
  "Operator Id": string;
  "Quick Rate Used": boolean;
  "Rated Via": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  Rating?: number;
}

export interface OperatorRemovedFromBackupProperties {
  /**
   * Business ID
   */
  "Business Id"?: string;
}

export interface OperatorRemovedFromLaborPoolProperties {
  /**
   * Business ID
   */
  "Business Id"?: string;
  "Last Active"?: string;
  "Last Bid"?: string;
  "Last Worked"?: string;
  /**
   * Operator ID
   */
  "Operator Id": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Operator Rating"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Operator Reliability"?: number;
  "Removed From Labor Pool Via": string;
}

export interface OperatorRemovedFromOpProperties {
  /**
   * Assignment ID
   */
  "Assignment Id": string;
  /**
   * Was the Op Day Cancelled within 12 hours of the start time?
   */
  "Is Late Cancellation"?: boolean;
  /**
   * Op ID
   */
  "Op Id": string;
}

export interface OperatorResumeViewedProperties {
  /**
   * Operator ID
   */
  "Operator Id": string;
  "Resume Viewed Via": string;
}

export interface OperatorTagAddedProperties {
  /**
   * Business ID
   */
  "Business Id"?: string;
  /**
   * Operator ID
   */
  "Operator Id": string;
  "Tag Added Via": string;
  "Tag Name": string;
}

export interface OperatorTagRemovedProperties {
  /**
   * Business ID
   */
  "Business Id"?: string;
  /**
   * Operator ID
   */
  "Operator Id"?: string;
  "Tag Name": string;
  "Tag Removed Via": string;
}

export interface OperatorTrainedWorkAreaAddedProperties {
  /**
   * Operator ID
   */
  "Operator Id": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Total Work Area Count": number;
  "Work Area Name"?: string;
}

export interface OperatorTrainedWorkAreaRemovedProperties {
  /**
   * Operator ID
   */
  "Operator Id": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Total Work Area Count": number;
  "Work Area Name"?: string;
}

export interface OperatorUnblockedProperties {
  /**
   * Business ID
   */
  "Business Id"?: string;
  /**
   * Operator ID
   */
  "Operator Id": string;
  "Unblocked Via": string;
}

export interface OperatorsExportedProperties {
  "Exported Via": string;
}

export interface OpsAddedToSummaryProperties {
  /**
   * Single Day or Multi Day
   */
  "Op Type": string;
  /**
   * Count added to summary
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Total Ops Added": number;
}

export interface OpsExportedProperties {
  "Exported Via": string;
}

export interface OpsSummaryFilteredProperties {
  "End Date"?: string;
  Location?: string;
  "Start Date"?: string;
  "Work Area Name"?: string;
}

export interface PayRestoredProperties {
  /**
   * Assignment ID
   */
  "Assignment Id": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Calculated Hours"?: number;
  "Has Completed Work"?: boolean;
  "Op Day Id": string;
  /**
   * Op ID
   */
  "Op Id": string;
  /**
   * Operator ID
   */
  "Operator Id": string;
}

export interface PaymentMethodUpdatedProperties {
  "Business Payment Type"?: string;
  "Updated Via"?: string;
}

export interface PortalCreatedProperties {
  /**
   * Business ID
   */
  "Business Id"?: string;
}

export interface RampPlanConfirmedProperties {
  "Current Labor Pool": string;
  /**
   * YYYY-MM-DD
   */
  "Target Date": string;
  "Target Labor Pool": string;
}

export interface RampPlanEditedProperties {
  "Edited Via"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Flexible Labor %": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Fte Count": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Ramp Length": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Ylp Target": number;
}

export interface RampPlanSettingsUpdatedProperties {
  "Demand Cycle": string;
  "Demand Fluctuation Frequency": string;
  "Demand Fluctuation Percent": string;
  "Full Time Employees": string;
  /**
   * YYYY-MM-DD
   */
  "Peak Season Start Date": string;
  "Staffed Percentage": string;
}

export interface ResumeCertificationsTabViewedProperties {
  "Viewed Via": string;
}

export interface ResumeEndorsementsTabViewedProperties {
  /**
   * Operator ID
   */
  "Operator Id": string;
}

export interface ResumeMilestonesTabViewedProperties {
  "Viewed Via": string;
}

export interface ResumeOverviewTabViewedProperties {
  "Viewed Via": string;
}

export interface ResumeSkillsExperienceTabViewedProperties {
  "Viewed Via": string;
}

export interface RosterDateSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Date Offset"?: number;
}

export interface RosterExportedProperties {
  "Roster View": string;
}

export interface RosterFilteredProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Location Filter"?: string[];
  "Roster View"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   * | Item Type | string |
   */
  "Search Bar Input"?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Work Area Filter"?: string[];
}

export interface RosterFiltersResetProperties {
  "Roster View"?: string;
}

export interface RosterOpTimelineClickedProperties {
  /**
   * Op ID
   */
  "Op Id": string;
}

export interface RosterSettingsUpdatedProperties {
  "Is Check In Code Required"?: boolean;
  "Is Check Out Code Required"?: boolean;
}

export interface RosterViewSettingsUpdatedProperties {
  "Is Hide Op Timeline"?: boolean;
  "Is Show Removed Operators"?: boolean;
  "Op Title Hidden"?: boolean;
  "Total Hours Hidden"?: boolean;
  "Work Area Hidden"?: boolean;
}

export interface RosterViewToggledProperties {
  "Roster View": string;
}

export interface ShowAlertsToggledProperties {
  "Is Hidden": boolean;
}

export interface ShowDisputesToggledProperties {
  "Is Toggled On": boolean;
}

export interface ShowOperatorsButtonClickedProperties {
  "Clicked Via": string;
}

export interface ShowOpsButtonClickedProperties {
  "Clicked Via": string;
}

export interface SuggestedOperatorsFilteredProperties {
  "Badge Filter"?: string;
  "Blocked Operator Filter"?: boolean;
  "Certifications Filter"?: string;
  "Distance Filter"?: string;
  "Industries Filter"?: string;
  "Name Search"?: string;
  "Ratings Filter"?: string;
  "Reliability Filter"?: string;
  "Removed From Labor Pool Filter"?: boolean;
  "Skills Filter"?: string;
  "Worked With Operator Filter"?: boolean;
  /**
   * Ylp type and tags
   */
  "Ylp Filter"?: string;
}

export interface TagCreatedProperties {
  /**
   * Business ID
   */
  "Business Id"?: string;
  "Tag Name": string;
}

export interface TagDeletedProperties {
  /**
   * Business ID
   */
  "Business Id"?: string;
  "Tag Name": string;
}

export interface TagEditedProperties {
  /**
   * Business ID
   */
  "Business Id"?: string;
  "New Name": string;
  "Previous Name": string;
}

export interface TagsButtonClickedProperties {
  "Tag Action"?: string;
}

export interface UserDeletedProperties {
  "Deleted Via": string;
}

export interface UserEditedProperties {
  "Edited Via"?: string;
}

export interface UserInviteGatePassedProperties {
  "Number of Users": string;
  "Passed Via": string;
}

export interface UserInvitedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Invitee Locations": string[];
  "Invitee Role": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Invitee Work Areas": string[];
  "Is Billing Contact": boolean;
  "User Invited Via": string;
}

export interface WfmActualThroughputEditedProperties {
  "Column Edited"?: string;
  "Edited Date": string;
}

export interface WfmAddEmployeesClickedProperties {
  "Employee Added"?: string;
  "Is From Day View"?: boolean;
  Shift?: string;
  /**
   * Work area that the Op was posted for
   */
  "Work Area"?: string;
}

export interface WfmAssignmentSubtypeAppliedProperties {
  "Assignment Subtype": string;
  "Employee Name"?: string;
  "Is From Day View"?: boolean;
  "Is Partial": boolean;
  /**
   * Work area that the Op was posted for
   */
  "Work Area"?: string;
}

export interface WfmDayViewDateChangedProperties {
  /**
   * Day being viewed
   */
  "Schedule Date": string;
}

export interface WfmDayViewFilteredProperties {
  /**
   * Grab all filters applied
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Filtered By": string[];
}

export interface WfmDayViewScheduleExportedProperties {
  /**
   * Schedule date being exported (not day of click)
   */
  "Schedule Date": string;
}

export interface WfmDepartmentDeletedProperties {
  /**
   * Numeric ID of the Department deleted
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Department ID": number;
  /**
   * Name of the Department deleted
   */
  "Department Name": string;
}

export interface WfmDepartmentsEditedProperties {
  /**
   * Numeric ID of the Department deleted
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Department ID": number;
}

export interface WfmEmployeeMasterScheduleExportedProperties {
  "Schedule End Date": string;
  "Schedule Start Date": string;
}

export interface WfmEmployeeReassignedProperties {
  "Is From Day View"?: boolean;
  "Is Partial": boolean;
  "New Work Area": string;
  "Original Work Area": string;
  Shift: string;
}

export interface WfmEmployeeUnassignedProperties {
  "Employee Name": string;
  "Is From Day View"?: boolean;
  Shift: string;
  /**
   * Work area that the Op was posted for
   */
  "Work Area": string;
}

export interface WfmEmployeesFilteredProperties {
  /**
   * The value set for the Primary Work Area filter (optional)
   */
  "Primary Work Area"?: string;
  /**
   * Value set for the shifts filter
   */
  Shifts?: string;
  /**
   * Value set for the trained work areas filter (optional- mutiple selections possible)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Trained Work Areas"?: string[];
  /**
   * Value set for the Work Days filter
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Work Days"?: string[];
}

export interface WfmForecastedThroughputEditedProperties {
  "Edited From"?: string;
}

export interface WfmFteCommsMessageFailureProperties {
  "Employee Name"?: string;
}

export interface WfmFteCommsMessageSentProperties {
  /**
   * Describes the Date the user clicked to create the message
   */
  "Message Created Date": string;
  /**
   * Describes the date that the message is regarding. For offers it will be the offer start date and for schedule it will be the start of the schedule date range
   */
  "Message Start Date"?: string;
  "Message Type": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Recipient Count"?: number;
}

export interface WfmNeededEmployeesAdjustedProperties {
  Adjustment?: string;
  "User Id": string;
  "Work Area Name"?: string;
}

export interface WfmProcessingQuantityEditedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Actual Demand After"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Actual Demand Before"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Actual Inbound After"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Actual Inbound Before"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Actual Outbound After"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Actual Outbound Before"?: number;
  /**
   * The date of the value being entered
   */
  Date: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Forecasted Demand After"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Forecasted Demand Before"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Forecasted Inbound After"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Forecasted Inbound Before"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Forecasted Outbound After"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Forecasted Outbound Before"?: number;
  "Shift Category"?: string;
  "Work Area Title"?: string;
}

export interface WfmProcessingQuantityFilteredProperties {
  "Date Range"?: string;
  "Shift Category"?: string;
  "Work Area Title"?: string;
}

export interface WfmProductDeletedProperties {
  /**
   * Numeric ID of the product deleted
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Product ID": number;
  /**
   * Name of the deleted product
   */
  "Product Name": string;
}

export interface WfmProductEditedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Average Percent of Volume"?: number;
  "Product Name": string;
  "Product Type": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Routing Stop Count"?: number;
  /**
   * Indicates if a routing stop was deleted from the product details slideout
   */
  "Routing Stop Deleted": boolean;
}

export interface WfmPtoAppliedProperties {
  "Employee Name"?: string;
  "Is From Day View"?: boolean;
  "PTO Date Range": string;
}

export interface WfmRosterFilteredProperties {
  "Child Shift"?: string;
  "Is Search": boolean;
  /**
   * The value set for the Primary Work Area filter (optional)
   */
  "Primary Work Area"?: string;
  "Trained Work Area"?: string;
  "Work Day"?: string;
}

export interface WfmScheduleFilteredProperties {
  "Shift Category"?: string;
}

export interface WfmShiftEditedProperties {
  "Edited Via"?: string;
}

export interface WfmTimekeepingAppliedProperties {
  "Absence Type": string;
}

export interface WfmVtoAppliedProperties {
  "Employee Name"?: string;
  "Is From Day View"?: boolean;
  "VTO Date": string;
  /**
   * Work area that the Op was posted for
   */
  "Work Area"?: string;
}

export interface WorkAreaAddedProperties {
  "Added Via"?: string;
  Department?: string;
  "Was Suggested": boolean;
  "Work Area Name"?: string;
  "Work Area Type"?: string;
}

export interface WorkAreaDeletedProperties {
  "Deleted Via": string;
  "Work Area Name"?: string;
}

export interface WorkAreaEditedProperties {
  Department?: string;
  "Edited Via"?: string;
  "New Area Name": string;
  "Previous Area Name": string;
}

export interface WorkAreaGatePassedProperties {
  "Number of Work Areas": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Work Areas": string[];
}

export interface YlpCleanupFiltersUpdatedProperties {
  "Last Active"?: string;
  "Last Bid"?: string;
  "Last Worked"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  Rating?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  Reliability?: number;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActiveTabClicked implements BaseEvent {
  event_type = 'Active Tab Clicked';
}

export class AdjustHoursButtonClicked implements BaseEvent {
  event_type = 'Adjust Hours Button Clicked';

  constructor(
    public event_properties: AdjustHoursButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AdjustToPostedHoursButtonClicked implements BaseEvent {
  event_type = 'Adjust To Posted Hours Button Clicked';

  constructor(
    public event_properties: AdjustToPostedHoursButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AdjustmentsActionableTabClicked implements BaseEvent {
  event_type = 'Adjustments Actionable Tab Clicked';
}

export class AdjustmentsExported implements BaseEvent {
  event_type = 'Adjustments Exported';
}

export class AdjustmentsHistoricalTabClicked implements BaseEvent {
  event_type = 'Adjustments Historical Tab Clicked';
}

export class AdjustmentsImported implements BaseEvent {
  event_type = 'Adjustments Imported';
}

export class AllBidsViewed implements BaseEvent {
  event_type = 'All Bids Viewed';
}

export class AllOperatorsTabClicked implements BaseEvent {
  event_type = 'All Operators Tab Clicked';
}

export class AllOperatorsTabFiltered implements BaseEvent {
  event_type = 'All Operators Tab Filtered';

  constructor(
    public event_properties?: AllOperatorsTabFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BackupListBidsViewed implements BaseEvent {
  event_type = 'Backup List Bids Viewed';
}

export class BannerOnboardingActionButtonClicked implements BaseEvent {
  event_type = 'Banner Onboarding Action Button Clicked';

  constructor(
    public event_properties: BannerOnboardingActionButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BannerOnboardingCollapsed implements BaseEvent {
  event_type = 'Banner Onboarding Collapsed';
}

export class BannerOnboardingEnabled implements BaseEvent {
  event_type = 'Banner Onboarding Enabled';

  constructor(
    public event_properties: BannerOnboardingEnabledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BannerOnboardingExpanded implements BaseEvent {
  event_type = 'Banner Onboarding Expanded';
}

export class BidAccepted implements BaseEvent {
  event_type = 'Bid Accepted';

  constructor(
    public event_properties: BidAcceptedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BidsFiltered implements BaseEvent {
  event_type = 'Bids Filtered';

  constructor(
    public event_properties: BidsFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BillingContactAdded implements BaseEvent {
  event_type = 'Billing Contact Added';

  constructor(
    public event_properties: BillingContactAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BlockedBidsViewed implements BaseEvent {
  event_type = 'Blocked Bids Viewed';
}

export class BreadcrumbClicked implements BaseEvent {
  event_type = 'Breadcrumb Clicked';

  constructor(
    public event_properties: BreadcrumbClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BulkBackupSkipped implements BaseEvent {
  event_type = 'Bulk Backup Skipped';

  constructor(
    public event_properties: BulkBackupSkippedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BulkPostButtonClicked implements BaseEvent {
  event_type = 'Bulk Post Button Clicked';
}

export class BulkPostFormReset implements BaseEvent {
  event_type = 'Bulk Post Form Reset';
}

export class BulkPostOpDeletedFromSummary implements BaseEvent {
  event_type = 'Bulk Post Op Deleted From Summary';
}

export class BulkPostOpDeletionReversed implements BaseEvent {
  event_type = 'Bulk Post Op Deletion Reversed';
}

export class BulkPostOpEditCanceled implements BaseEvent {
  event_type = 'Bulk Post Op Edit Canceled';
}

export class BulkPostOpEdited implements BaseEvent {
  event_type = 'Bulk Post Op Edited';

  constructor(
    public event_properties: BulkPostOpEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BulkPostOpRetryClicked implements BaseEvent {
  event_type = 'Bulk Post Op Retry Clicked';
}

export class BulkPostSubmitted implements BaseEvent {
  event_type = 'Bulk Post Submitted';

  constructor(
    public event_properties?: BulkPostSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BusinessInfoUpdated implements BaseEvent {
  event_type = 'Business Info Updated';
}

export class BusinessNotificationsPageViewed implements BaseEvent {
  event_type = 'Business Notifications Page Viewed';
}

export class BusinessPortalEntered implements BaseEvent {
  event_type = 'Business Portal Entered';

  constructor(
    public event_properties: BusinessPortalEnteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BusinessSelected implements BaseEvent {
  event_type = 'Business Selected';

  constructor(
    public event_properties: BusinessSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BusinessSwitched implements BaseEvent {
  event_type = 'Business Switched';
}

export class CancelOpClicked implements BaseEvent {
  event_type = 'Cancel Op Clicked';

  constructor(
    public event_properties: CancelOpClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CancelOpSummaryClicked implements BaseEvent {
  event_type = 'Cancel Op Summary Clicked';

  constructor(
    public event_properties: CancelOpSummaryClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChatMessageSent implements BaseEvent {
  event_type = 'Chat Message Sent';
}

export class CheckInButtonClicked implements BaseEvent {
  event_type = 'Check In Button Clicked';

  constructor(
    public event_properties: CheckInButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckOutButtonClicked implements BaseEvent {
  event_type = 'Check Out Button Clicked';

  constructor(
    public event_properties: CheckOutButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CompletedTabClicked implements BaseEvent {
  event_type = 'Completed Tab Clicked';
}

export class CreateProfileSource implements BaseEvent {
  event_type = 'Create Profile Source';

  constructor(
    public event_properties: CreateProfileSourceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DisputeReversed implements BaseEvent {
  event_type = 'Dispute Reversed';

  constructor(
    public event_properties: DisputeReversedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DoNotPayButtonClicked implements BaseEvent {
  event_type = 'Do Not Pay Button Clicked';

  constructor(
    public event_properties: DoNotPayButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DocumentDeleted implements BaseEvent {
  event_type = 'Document Deleted';

  constructor(
    public event_properties: DocumentDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DocumentUploaded implements BaseEvent {
  event_type = 'Document Uploaded';

  constructor(
    public event_properties: DocumentUploadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditTemplateButtonClicked implements BaseEvent {
  event_type = 'Edit Template Button Clicked';
}

export class ExpiredBidsViewed implements BaseEvent {
  event_type = 'Expired Bids Viewed';
}

export class FromTemplateButtonClicked implements BaseEvent {
  event_type = 'From Template Button Clicked';
}

export class GatedOnboardingCompleted implements BaseEvent {
  event_type = 'Gated Onboarding Completed';
}

export class GeofenceCreated implements BaseEvent {
  event_type = 'Geofence Created';

  constructor(
    public event_properties: GeofenceCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GeofenceMapCleared implements BaseEvent {
  event_type = 'Geofence Map Cleared';

  constructor(
    public event_properties: GeofenceMapClearedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GeofencePreviewed implements BaseEvent {
  event_type = 'Geofence Previewed';

  constructor(
    public event_properties: GeofencePreviewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GeofenceUpdated implements BaseEvent {
  event_type = 'Geofence Updated';

  constructor(
    public event_properties: GeofenceUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HvdAdjustmentsFiltered implements BaseEvent {
  event_type = 'Hvd Adjustments Filtered';

  constructor(
    public event_properties: HvdAdjustmentsFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HvdFiltersReset implements BaseEvent {
  event_type = 'Hvd Filters Reset';
}

export class HvdOpsFiltered implements BaseEvent {
  event_type = 'Hvd Ops Filtered';

  constructor(
    public event_properties: HvdOpsFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HvdRatingsFiltered implements BaseEvent {
  event_type = 'Hvd Ratings Filtered';

  constructor(
    public event_properties?: HvdRatingsFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IndustryGatePassed implements BaseEvent {
  event_type = 'Industry Gate Passed';

  constructor(
    public event_properties: IndustryGatePassedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InlineInButtonClicked implements BaseEvent {
  event_type = 'Inline In Button Clicked';

  constructor(
    public event_properties: InlineInButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InlineOutButtonClicked implements BaseEvent {
  event_type = 'Inline Out Button Clicked';

  constructor(
    public event_properties: InlineOutButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InvitedBidsViewed implements BaseEvent {
  event_type = 'Invited Bids Viewed';
}

export class LaborPoolFiltered implements BaseEvent {
  event_type = 'Labor Pool Filtered';

  constructor(
    public event_properties?: LaborPoolFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LinkAdded implements BaseEvent {
  event_type = 'Link Added';

  constructor(
    public event_properties: LinkAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LinkDeleted implements BaseEvent {
  event_type = 'Link Deleted';

  constructor(
    public event_properties: LinkDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LinkEdited implements BaseEvent {
  event_type = 'Link Edited';

  constructor(
    public event_properties: LinkEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LocationAdded implements BaseEvent {
  event_type = 'Location Added';

  constructor(
    public event_properties: LocationAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LocationDeleted implements BaseEvent {
  event_type = 'Location Deleted';

  constructor(
    public event_properties: LocationDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LocationFlowExited implements BaseEvent {
  event_type = 'Location Flow Exited';

  constructor(
    public event_properties: LocationFlowExitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LocationUpdated implements BaseEvent {
  event_type = 'Location Updated';

  constructor(
    public event_properties: LocationUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoggedIn implements BaseEvent {
  event_type = 'Logged In';

  constructor(
    public event_properties: LoggedInProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LogoUpdated implements BaseEvent {
  event_type = 'Logo Updated';

  constructor(
    public event_properties: LogoUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MoreWorkAreasButtonClicked implements BaseEvent {
  event_type = 'More Work Areas Button Clicked';
}

export class NewOpButtonClicked implements BaseEvent {
  event_type = 'New Op Button Clicked';
}

export class OpCodesSidebarViewed implements BaseEvent {
  event_type = 'Op Codes Sidebar Viewed';

  constructor(
    public event_properties: OpCodesSidebarViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpContactAdded implements BaseEvent {
  event_type = 'Op Contact Added';

  constructor(
    public event_properties?: OpContactAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpContactDeleted implements BaseEvent {
  event_type = 'Op Contact Deleted';
}

export class OpContactEdited implements BaseEvent {
  event_type = 'Op Contact Edited';
}

export class OpDayAdjusted implements BaseEvent {
  event_type = 'Op Day Adjusted';

  constructor(
    public event_properties: OpDayAdjustedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpDayCancelled implements BaseEvent {
  event_type = 'Op Day Cancelled';

  constructor(
    public event_properties: OpDayCancelledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpDayDisputed implements BaseEvent {
  event_type = 'Op Day Disputed';

  constructor(
    public event_properties: OpDayDisputedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpDayMarkedAbsent implements BaseEvent {
  event_type = 'Op Day Marked Absent';

  constructor(
    public event_properties: OpDayMarkedAbsentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpDeactivated implements BaseEvent {
  event_type = 'Op Deactivated';

  constructor(
    public event_properties: OpDeactivatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpDetailsViewed implements BaseEvent {
  event_type = 'Op Details Viewed';

  constructor(
    public event_properties: OpDetailsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpEdited implements BaseEvent {
  event_type = 'Op Edited';

  constructor(
    public event_properties: OpEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpInfoSidebarViewed implements BaseEvent {
  event_type = 'Op Info Sidebar Viewed';

  constructor(
    public event_properties: OpInfoSidebarViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpPosted implements BaseEvent {
  event_type = 'Op Posted';

  constructor(
    public event_properties: OpPostedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpReactivated implements BaseEvent {
  event_type = 'Op Reactivated';

  constructor(
    public event_properties: OpReactivatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpScheduled implements BaseEvent {
  event_type = 'Op Scheduled';

  constructor(
    public event_properties: OpScheduledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpTemplateCollapsed implements BaseEvent {
  event_type = 'Op Template Collapsed';
}

export class OpTemplateCreated implements BaseEvent {
  event_type = 'Op Template Created';

  constructor(
    public event_properties: OpTemplateCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpTemplateDeleted implements BaseEvent {
  event_type = 'Op Template Deleted';
}

export class OpTemplateEdited implements BaseEvent {
  event_type = 'Op Template Edited';

  constructor(
    public event_properties: OpTemplateEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpTemplateExpanded implements BaseEvent {
  event_type = 'Op Template Expanded';
}

export class OpTemplatesButtonClicked implements BaseEvent {
  event_type = 'Op Templates Button Clicked';
}

export class OperatorAddedToBackup implements BaseEvent {
  event_type = 'Operator Added to Backup';

  constructor(
    public event_properties: OperatorAddedToBackupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorAddedToLaborPool implements BaseEvent {
  event_type = 'Operator Added To Labor Pool';

  constructor(
    public event_properties: OperatorAddedToLaborPoolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorBlocked implements BaseEvent {
  event_type = 'Operator Blocked';

  constructor(
    public event_properties: OperatorBlockedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorEndorsed implements BaseEvent {
  event_type = 'Operator Endorsed';

  constructor(
    public event_properties: OperatorEndorsedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorInvitedToOp implements BaseEvent {
  event_type = 'Operator Invited To Op';

  constructor(
    public event_properties: OperatorInvitedToOpProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorRated implements BaseEvent {
  event_type = 'Operator Rated';

  constructor(
    public event_properties: OperatorRatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorRemovedFromBackup implements BaseEvent {
  event_type = 'Operator Removed From Backup';

  constructor(
    public event_properties?: OperatorRemovedFromBackupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorRemovedFromLaborPool implements BaseEvent {
  event_type = 'Operator Removed From Labor Pool';

  constructor(
    public event_properties: OperatorRemovedFromLaborPoolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorRemovedFromOp implements BaseEvent {
  event_type = 'Operator Removed From Op';

  constructor(
    public event_properties: OperatorRemovedFromOpProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorResumeViewed implements BaseEvent {
  event_type = 'Operator Resume Viewed';

  constructor(
    public event_properties: OperatorResumeViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorTagAdded implements BaseEvent {
  event_type = 'Operator Tag Added';

  constructor(
    public event_properties: OperatorTagAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorTagRemoved implements BaseEvent {
  event_type = 'Operator Tag Removed';

  constructor(
    public event_properties: OperatorTagRemovedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorTrainedWorkAreaAdded implements BaseEvent {
  event_type = 'Operator Trained Work Area Added';

  constructor(
    public event_properties: OperatorTrainedWorkAreaAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorTrainedWorkAreaRemoved implements BaseEvent {
  event_type = 'Operator Trained Work Area Removed';

  constructor(
    public event_properties: OperatorTrainedWorkAreaRemovedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorUnblocked implements BaseEvent {
  event_type = 'Operator Unblocked';

  constructor(
    public event_properties: OperatorUnblockedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OperatorsExported implements BaseEvent {
  event_type = 'Operators Exported';

  constructor(
    public event_properties: OperatorsExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpsAddedToSummary implements BaseEvent {
  event_type = 'Ops Added To Summary';

  constructor(
    public event_properties: OpsAddedToSummaryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpsExported implements BaseEvent {
  event_type = 'Ops Exported';

  constructor(
    public event_properties: OpsExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpsSummaryFiltered implements BaseEvent {
  event_type = 'Ops Summary Filtered';

  constructor(
    public event_properties?: OpsSummaryFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayNowBannerClicked implements BaseEvent {
  event_type = 'Pay Now Banner Clicked';
}

export class PayRestored implements BaseEvent {
  event_type = 'Pay Restored';

  constructor(
    public event_properties: PayRestoredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PaymentMethodUpdated implements BaseEvent {
  event_type = 'Payment Method Updated';

  constructor(
    public event_properties?: PaymentMethodUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PortalCreated implements BaseEvent {
  event_type = 'Portal Created';

  constructor(
    public event_properties?: PortalCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PostOpButtonClicked implements BaseEvent {
  event_type = 'Post Op Button Clicked';
}

export class RampPlanConfirmed implements BaseEvent {
  event_type = 'Ramp Plan Confirmed';

  constructor(
    public event_properties: RampPlanConfirmedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RampPlanEdited implements BaseEvent {
  event_type = 'Ramp Plan Edited';

  constructor(
    public event_properties: RampPlanEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RampPlanSettingsIconClicked implements BaseEvent {
  event_type = 'Ramp Plan Settings Icon Clicked';
}

export class RampPlanSettingsUpdated implements BaseEvent {
  event_type = 'Ramp Plan Settings Updated';

  constructor(
    public event_properties: RampPlanSettingsUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReadyToWorkBidsViewed implements BaseEvent {
  event_type = 'Ready To Work Bids Viewed';
}

export class RestorePayButtonClicked implements BaseEvent {
  event_type = 'Restore Pay Button Clicked';
}

export class ResumeCertificationsTabViewed implements BaseEvent {
  event_type = 'Resume Certifications Tab Viewed';

  constructor(
    public event_properties: ResumeCertificationsTabViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ResumeEndorsementsTabViewed implements BaseEvent {
  event_type = 'Resume Endorsements Tab Viewed';

  constructor(
    public event_properties: ResumeEndorsementsTabViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ResumeMilestonesTabViewed implements BaseEvent {
  event_type = 'Resume Milestones Tab Viewed';

  constructor(
    public event_properties: ResumeMilestonesTabViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ResumeOverviewTabViewed implements BaseEvent {
  event_type = 'Resume Overview Tab Viewed';

  constructor(
    public event_properties: ResumeOverviewTabViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ResumeSkillsExperienceTabViewed implements BaseEvent {
  event_type = 'Resume Skills & Experience Tab Viewed';

  constructor(
    public event_properties: ResumeSkillsExperienceTabViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RosterDateSelected implements BaseEvent {
  event_type = 'Roster Date Selected';

  constructor(
    public event_properties?: RosterDateSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RosterExported implements BaseEvent {
  event_type = 'Roster Exported';

  constructor(
    public event_properties: RosterExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RosterFiltered implements BaseEvent {
  event_type = 'Roster Filtered';

  constructor(
    public event_properties?: RosterFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RosterFiltersReset implements BaseEvent {
  event_type = 'Roster Filters Reset';

  constructor(
    public event_properties?: RosterFiltersResetProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RosterOpTimelineClicked implements BaseEvent {
  event_type = 'Roster Op Timeline Clicked';

  constructor(
    public event_properties: RosterOpTimelineClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RosterSettingsUpdated implements BaseEvent {
  event_type = 'Roster Settings Updated';

  constructor(
    public event_properties?: RosterSettingsUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RosterViewSettingsButtonClicked implements BaseEvent {
  event_type = 'Roster View Settings Button Clicked';
}

export class RosterViewSettingsUpdated implements BaseEvent {
  event_type = 'Roster View Settings Updated';

  constructor(
    public event_properties?: RosterViewSettingsUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RosterViewToggled implements BaseEvent {
  event_type = 'Roster View Toggled';

  constructor(
    public event_properties: RosterViewToggledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ScheduleConflictBidsViewed implements BaseEvent {
  event_type = 'Schedule Conflict Bids Viewed';
}

export class SendMessageClicked implements BaseEvent {
  event_type = 'Send Message Clicked';
}

export class SettingsButtonClicked implements BaseEvent {
  event_type = 'Settings Button Clicked';
}

export class ShowAlertsToggled implements BaseEvent {
  event_type = 'Show Alerts Toggled';

  constructor(
    public event_properties: ShowAlertsToggledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShowDisputesToggled implements BaseEvent {
  event_type = 'Show Disputes Toggled';

  constructor(
    public event_properties: ShowDisputesToggledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShowOperatorsButtonClicked implements BaseEvent {
  event_type = 'Show Operators Button Clicked';

  constructor(
    public event_properties: ShowOperatorsButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShowOpsButtonClicked implements BaseEvent {
  event_type = 'Show Ops Button Clicked';

  constructor(
    public event_properties: ShowOpsButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StartRatingBannerClicked implements BaseEvent {
  event_type = 'Start Rating Banner Clicked';
}

export class SuggestedOperatorsFiltered implements BaseEvent {
  event_type = 'Suggested Operators Filtered';

  constructor(
    public event_properties?: SuggestedOperatorsFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TagCreated implements BaseEvent {
  event_type = 'Tag Created';

  constructor(
    public event_properties: TagCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TagDeleted implements BaseEvent {
  event_type = 'Tag Deleted';

  constructor(
    public event_properties: TagDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TagEdited implements BaseEvent {
  event_type = 'Tag Edited';

  constructor(
    public event_properties: TagEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TagsButtonClicked implements BaseEvent {
  event_type = 'Tags Button Clicked';

  constructor(
    public event_properties?: TagsButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TimeOffAdded implements BaseEvent {
  event_type = 'Time-Off Added';
}

export class TrainedOperatorsEditIconClicked implements BaseEvent {
  event_type = 'Trained Operators Edit Icon Clicked';
}

export class UpcomingTabClicked implements BaseEvent {
  event_type = 'Upcoming Tab Clicked';
}

export class UserDeleted implements BaseEvent {
  event_type = 'User Deleted';

  constructor(
    public event_properties: UserDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserEdited implements BaseEvent {
  event_type = 'User Edited';

  constructor(
    public event_properties?: UserEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserInviteGatePassed implements BaseEvent {
  event_type = 'User Invite Gate Passed';

  constructor(
    public event_properties: UserInviteGatePassedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserInviteResent implements BaseEvent {
  event_type = 'User Invite Resent';
}

export class UserInvited implements BaseEvent {
  event_type = 'User Invited';

  constructor(
    public event_properties: UserInvitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmActualThroughputEdited implements BaseEvent {
  event_type = 'WFM Actual Throughput Edited';

  constructor(
    public event_properties: WfmActualThroughputEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmAddEmployeesClicked implements BaseEvent {
  event_type = 'WFM Add Employees Clicked';

  constructor(
    public event_properties?: WfmAddEmployeesClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmAnalyticsViewed implements BaseEvent {
  event_type = 'WFM Analytics Viewed';
}

export class WfmAssignmentScheduleExported implements BaseEvent {
  event_type = 'WFM Assignment Schedule Exported';
}

export class WfmAssignmentSubtypeApplied implements BaseEvent {
  event_type = 'WFM Assignment Subtype Applied';

  constructor(
    public event_properties: WfmAssignmentSubtypeAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmDataSynced implements BaseEvent {
  event_type = 'WFM Data Synced';
}

export class WfmDateChanged implements BaseEvent {
  event_type = 'WFM Date Changed';
}

export class WfmDayViewDateChanged implements BaseEvent {
  event_type = 'WFM Day View Date Changed';

  constructor(
    public event_properties: WfmDayViewDateChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmDayViewFiltered implements BaseEvent {
  event_type = 'WFM Day View Filtered';

  constructor(
    public event_properties: WfmDayViewFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmDayViewScheduleExported implements BaseEvent {
  event_type = 'WFM Day View Schedule Exported';

  constructor(
    public event_properties: WfmDayViewScheduleExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmDepartmentAdded implements BaseEvent {
  event_type = 'WFM Department Added';
}

export class WfmDepartmentDeleted implements BaseEvent {
  event_type = 'WFM Department Deleted';

  constructor(
    public event_properties: WfmDepartmentDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmDepartmentsEdited implements BaseEvent {
  event_type = 'WFM Departments Edited';

  constructor(
    public event_properties: WfmDepartmentsEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmDepartmentsUpdated implements BaseEvent {
  event_type = 'WFM Departments Updated';
}

export class WfmEmployeeEdited implements BaseEvent {
  event_type = 'WFM Employee Edited';
}

export class WfmEmployeeManuallyAdded implements BaseEvent {
  event_type = 'WFM Employee Manually Added';
}

export class WfmEmployeeMasterScheduleExported implements BaseEvent {
  event_type = 'WFM Employee Master Schedule Exported';

  constructor(
    public event_properties: WfmEmployeeMasterScheduleExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmEmployeeReassigned implements BaseEvent {
  event_type = 'WFM Employee Reassigned';

  constructor(
    public event_properties: WfmEmployeeReassignedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmEmployeeRemoved implements BaseEvent {
  event_type = 'WFM Employee Removed';
}

export class WfmEmployeeTabViewed implements BaseEvent {
  event_type = 'WFM Employee Tab Viewed';
}

export class WfmEmployeeUnassigned implements BaseEvent {
  event_type = 'WFM Employee Unassigned';

  constructor(
    public event_properties: WfmEmployeeUnassignedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmEmployeesFiltered implements BaseEvent {
  event_type = 'WFM Employees Filtered';

  constructor(
    public event_properties?: WfmEmployeesFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmForecastedThroughputEdited implements BaseEvent {
  event_type = 'WFM Forecasted Throughput Edited';

  constructor(
    public event_properties?: WfmForecastedThroughputEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmFteCommsMessageFailure implements BaseEvent {
  event_type = 'WFM FTE Comms Message Failure';

  constructor(
    public event_properties?: WfmFteCommsMessageFailureProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmFteCommsMessageSent implements BaseEvent {
  event_type = 'WFM FTE Comms Message Sent';

  constructor(
    public event_properties: WfmFteCommsMessageSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmFteCommsOfferAccepted implements BaseEvent {
  event_type = 'WFM FTE Comms Offer Accepted';
}

export class WfmLocationClicked implements BaseEvent {
  event_type = 'WFM Location Clicked';
}

export class WfmNeededEmployeesAdjusted implements BaseEvent {
  event_type = 'WFM Needed Employees Adjusted';

  constructor(
    public event_properties: WfmNeededEmployeesAdjustedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmProcessingQuantityEdited implements BaseEvent {
  event_type = 'WFM Processing Quantity Edited';

  constructor(
    public event_properties: WfmProcessingQuantityEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmProcessingQuantityExported implements BaseEvent {
  event_type = 'WFM Processing Quantity Exported';
}

export class WfmProcessingQuantityFiltered implements BaseEvent {
  event_type = 'WFM Processing Quantity Filtered';

  constructor(
    public event_properties?: WfmProcessingQuantityFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmProcessingQuantityUploaded implements BaseEvent {
  event_type = 'WFM Processing Quantity Uploaded';
}

export class WfmProcessingQuantityViewed implements BaseEvent {
  event_type = 'WFM Processing Quantity Viewed';
}

export class WfmProductCreated implements BaseEvent {
  event_type = 'WFM Product Created';
}

export class WfmProductDeleted implements BaseEvent {
  event_type = 'WFM Product Deleted';

  constructor(
    public event_properties: WfmProductDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmProductDetailsViewed implements BaseEvent {
  event_type = 'WFM Product Details Viewed';
}

export class WfmProductEdited implements BaseEvent {
  event_type = 'WFM Product Edited';

  constructor(
    public event_properties: WfmProductEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmProductSearched implements BaseEvent {
  event_type = 'WFM Product Searched';
}

export class WfmProductsRoutingsViewed implements BaseEvent {
  event_type = 'WFM Products & Routings Viewed';
}

export class WfmPtoApplied implements BaseEvent {
  event_type = 'WFM PTO Applied';

  constructor(
    public event_properties: WfmPtoAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmRosterExported implements BaseEvent {
  event_type = 'WFM Roster Exported';
}

export class WfmRosterFiltered implements BaseEvent {
  event_type = 'WFM Roster Filtered';

  constructor(
    public event_properties: WfmRosterFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmRosterUploaded implements BaseEvent {
  event_type = 'WFM Roster Uploaded';
}

export class WfmScheduleFiltered implements BaseEvent {
  event_type = 'WFM Schedule Filtered';

  constructor(
    public event_properties?: WfmScheduleFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmSettingsClicked implements BaseEvent {
  event_type = 'WFM Settings Clicked';
}

export class WfmSettingsUpdated implements BaseEvent {
  event_type = 'WFM Settings Updated';
}

export class WfmShiftCategoryCreated implements BaseEvent {
  event_type = 'WFM Shift Category Created';
}

export class WfmShiftCategoryDeleted implements BaseEvent {
  event_type = 'WFM Shift Category Deleted';
}

export class WfmShiftCategoryEdited implements BaseEvent {
  event_type = 'WFM Shift Category Edited';
}

export class WfmShiftCreated implements BaseEvent {
  event_type = 'WFM Shift Created';
}

export class WfmShiftDeleted implements BaseEvent {
  event_type = 'WFM Shift Deleted';
}

export class WfmShiftEdited implements BaseEvent {
  event_type = 'WFM Shift Edited';

  constructor(
    public event_properties?: WfmShiftEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmSummaryTabViewed implements BaseEvent {
  event_type = 'WFM Summary Tab Viewed';
}

export class WfmSummaryViewToggled implements BaseEvent {
  event_type = 'WFM Summary View Toggled';
}

export class WfmTimekeepingApplied implements BaseEvent {
  event_type = 'WFM Timekeeping Applied';

  constructor(
    public event_properties: WfmTimekeepingAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmVtoApplied implements BaseEvent {
  event_type = 'WFM VTO Applied';

  constructor(
    public event_properties: WfmVtoAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WfmWorkAreaDateCardClicked implements BaseEvent {
  event_type = 'WFM Work Area Date Card Clicked';
}

export class WithdrawnBidsViewed implements BaseEvent {
  event_type = 'Withdrawn Bids Viewed';
}

export class WorkAreaAdded implements BaseEvent {
  event_type = 'Work Area Added';

  constructor(
    public event_properties: WorkAreaAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WorkAreaCollapsed implements BaseEvent {
  event_type = 'Work Area Collapsed';
}

export class WorkAreaDeleted implements BaseEvent {
  event_type = 'Work Area Deleted';

  constructor(
    public event_properties: WorkAreaDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WorkAreaEdited implements BaseEvent {
  event_type = 'Work Area Edited';

  constructor(
    public event_properties: WorkAreaEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WorkAreaExpanded implements BaseEvent {
  event_type = 'Work Area Expanded';
}

export class WorkAreaGatePassed implements BaseEvent {
  event_type = 'Work Area Gate Passed';

  constructor(
    public event_properties: WorkAreaGatePassedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class YlpBidsViewed implements BaseEvent {
  event_type = 'Ylp Bids Viewed';
}

export class YlpCleanupButtonClicked implements BaseEvent {
  event_type = 'Ylp Cleanup Button Clicked';
}

export class YlpCleanupFiltersUpdated implements BaseEvent {
  event_type = 'Ylp Cleanup Filters Updated';

  constructor(
    public event_properties?: YlpCleanupFiltersUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Active Tab Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Active%20Tab%20Clicked)
   *
   * When a User clicks on the Active tab within the Ops page
   *
   * @param options Amplitude event options.
   */
  activeTabClicked(
    options?: EventOptions,
  ) {
    return this.track(new ActiveTabClicked(), options);
  }

  /**
   * Adjust Hours Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Adjust%20Hours%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Clicked Via)
   * @param options Amplitude event options.
   */
  adjustHoursButtonClicked(
    properties: AdjustHoursButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AdjustHoursButtonClicked(properties), options);
  }

  /**
   * Adjust To Posted Hours Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Adjust%20To%20Posted%20Hours%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Calculated Hours)
   * @param options Amplitude event options.
   */
  adjustToPostedHoursButtonClicked(
    properties: AdjustToPostedHoursButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AdjustToPostedHoursButtonClicked(properties), options);
  }

  /**
   * Adjustments Actionable Tab Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Adjustments%20Actionable%20Tab%20Clicked)
   *
   * When a User clicks on the Actiobnable Tab within the Adjustments Tab in the HVD
   *
   * @param options Amplitude event options.
   */
  adjustmentsActionableTabClicked(
    options?: EventOptions,
  ) {
    return this.track(new AdjustmentsActionableTabClicked(), options);
  }

  /**
   * Adjustments Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Adjustments%20Exported)
   *
   * When a User exports the Actionable Adjustments
   *
   * @param options Amplitude event options.
   */
  adjustmentsExported(
    options?: EventOptions,
  ) {
    return this.track(new AdjustmentsExported(), options);
  }

  /**
   * Adjustments Historical Tab Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Adjustments%20Historical%20Tab%20Clicked)
   *
   * When a User clicks on the Historical Tab within the Adjustments Tab in the HVD
   *
   * @param options Amplitude event options.
   */
  adjustmentsHistoricalTabClicked(
    options?: EventOptions,
  ) {
    return this.track(new AdjustmentsHistoricalTabClicked(), options);
  }

  /**
   * Adjustments Imported
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Adjustments%20Imported)
   *
   * When a User imports the Actionable Adjustments
   *
   * @param options Amplitude event options.
   */
  adjustmentsImported(
    options?: EventOptions,
  ) {
    return this.track(new AdjustmentsImported(), options);
  }

  /**
   * All Bids Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/All%20Bids%20Viewed)
   *
   * When a User clicks on the Bids tab in the Bids table on the Op Details page
   *
   * @param options Amplitude event options.
   */
  allBidsViewed(
    options?: EventOptions,
  ) {
    return this.track(new AllBidsViewed(), options);
  }

  /**
   * All Operators Tab Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/All%20Operators%20Tab%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  allOperatorsTabClicked(
    options?: EventOptions,
  ) {
    return this.track(new AllOperatorsTabClicked(), options);
  }

  /**
   * All Operators Tab Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/All%20Operators%20Tab%20Filtered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Badges Filter)
   * @param options Amplitude event options.
   */
  allOperatorsTabFiltered(
    properties?: AllOperatorsTabFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new AllOperatorsTabFiltered(properties), options);
  }

  /**
   * Backup List Bids Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Backup%20List%20Bids%20Viewed)
   *
   * When a User clicks on the Backup tab in the Bids table on the Op Details page
   *
   * @param options Amplitude event options.
   */
  backupListBidsViewed(
    options?: EventOptions,
  ) {
    return this.track(new BackupListBidsViewed(), options);
  }

  /**
   * Banner Onboarding Action Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Banner%20Onboarding%20Action%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Banner Button Name)
   * @param options Amplitude event options.
   */
  bannerOnboardingActionButtonClicked(
    properties: BannerOnboardingActionButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BannerOnboardingActionButtonClicked(properties), options);
  }

  /**
   * Banner Onboarding Collapsed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Banner%20Onboarding%20Collapsed)
   *
   * When a User clicks on the upward facing caret on the Banner Onboarding
   *
   * @param options Amplitude event options.
   */
  bannerOnboardingCollapsed(
    options?: EventOptions,
  ) {
    return this.track(new BannerOnboardingCollapsed(), options);
  }

  /**
   * Banner Onboarding Enabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Banner%20Onboarding%20Enabled)
   *
   * Owner: Lucas Nogueira
   *
   * @param properties The event's properties (e.g. Business Id)
   * @param options Amplitude event options.
   */
  bannerOnboardingEnabled(
    properties: BannerOnboardingEnabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new BannerOnboardingEnabled(properties), options);
  }

  /**
   * Banner Onboarding Expanded
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Banner%20Onboarding%20Expanded)
   *
   * When a User clicks on the downward facing caret on the Banner Onboarding
   *
   * @param options Amplitude event options.
   */
  bannerOnboardingExpanded(
    options?: EventOptions,
  ) {
    return this.track(new BannerOnboardingExpanded(), options);
  }

  /**
   * Bid Accepted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Bid%20Accepted)
   *
   * When an operator is accepted, either manually by the business or via autofill
   *
   * Owner: tnguyen@veryableops.com
   *
   * @param properties The event's properties (e.g. Accepted Lead Time)
   * @param options Amplitude event options.
   */
  bidAccepted(
    properties: BidAcceptedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BidAccepted(properties), options);
  }

  /**
   * Bids Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Bids%20Filtered)
   *
   * When Business Users filter their submitted bids
   *
   * Owner: ukc@veryableops.com
   *
   * @param properties The event's properties (e.g. Badges Filter)
   * @param options Amplitude event options.
   */
  bidsFiltered(
    properties: BidsFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new BidsFiltered(properties), options);
  }

  /**
   * Billing Contact Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Billing%20Contact%20Added)
   *
   * Triggered when a previous User is set as billing contact
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Contact Added Via)
   * @param options Amplitude event options.
   */
  billingContactAdded(
    properties: BillingContactAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillingContactAdded(properties), options);
  }

  /**
   * Blocked Bids Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Blocked%20Bids%20Viewed)
   *
   * When a User clicks on the Blocked tab in the Bids table on the Op Details page
   *
   * @param options Amplitude event options.
   */
  blockedBidsViewed(
    options?: EventOptions,
  ) {
    return this.track(new BlockedBidsViewed(), options);
  }

  /**
   * Breadcrumb Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Breadcrumb%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Clicked Back To)
   * @param options Amplitude event options.
   */
  breadcrumbClicked(
    properties: BreadcrumbClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BreadcrumbClicked(properties), options);
  }

  /**
   * Bulk Backup Skipped
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Bulk%20Backup%20Skipped)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Op Id)
   * @param options Amplitude event options.
   */
  bulkBackupSkipped(
    properties: BulkBackupSkippedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BulkBackupSkipped(properties), options);
  }

  /**
   * Bulk Post Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Bulk%20Post%20Button%20Clicked)
   *
   * When a User clicks on the Bulk Post Button within the Post Op Button
   *
   * @param options Amplitude event options.
   */
  bulkPostButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new BulkPostButtonClicked(), options);
  }

  /**
   * Bulk Post Form Reset
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Bulk%20Post%20Form%20Reset)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  bulkPostFormReset(
    options?: EventOptions,
  ) {
    return this.track(new BulkPostFormReset(), options);
  }

  /**
   * Bulk Post Op Deleted From Summary
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Bulk%20Post%20Op%20Deleted%20From%20Summary)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  bulkPostOpDeletedFromSummary(
    options?: EventOptions,
  ) {
    return this.track(new BulkPostOpDeletedFromSummary(), options);
  }

  /**
   * Bulk Post Op Deletion Reversed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Bulk%20Post%20Op%20Deletion%20Reversed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  bulkPostOpDeletionReversed(
    options?: EventOptions,
  ) {
    return this.track(new BulkPostOpDeletionReversed(), options);
  }

  /**
   * Bulk Post Op Edit Canceled
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Bulk%20Post%20Op%20Edit%20Canceled)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  bulkPostOpEditCanceled(
    options?: EventOptions,
  ) {
    return this.track(new BulkPostOpEditCanceled(), options);
  }

  /**
   * Bulk Post Op Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Bulk%20Post%20Op%20Edited)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. New Total Ops Added)
   * @param options Amplitude event options.
   */
  bulkPostOpEdited(
    properties: BulkPostOpEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BulkPostOpEdited(properties), options);
  }

  /**
   * Bulk Post Op Retry Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Bulk%20Post%20Op%20Retry%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  bulkPostOpRetryClicked(
    options?: EventOptions,
  ) {
    return this.track(new BulkPostOpRetryClicked(), options);
  }

  /**
   * Bulk Post Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Bulk%20Post%20Submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Total Ops Posted)
   * @param options Amplitude event options.
   */
  bulkPostSubmitted(
    properties?: BulkPostSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BulkPostSubmitted(properties), options);
  }

  /**
   * Business Info Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Business%20Info%20Updated)
   *
   * When a User updates the Business Name, URL, Description
   *
   * @param options Amplitude event options.
   */
  businessInfoUpdated(
    options?: EventOptions,
  ) {
    return this.track(new BusinessInfoUpdated(), options);
  }

  /**
   * Business Notifications Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Business%20Notifications%20Page%20Viewed)
   *
   * This event tracks when a user views the notifications page in the Business App
   *
   * @param options Amplitude event options.
   */
  businessNotificationsPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new BusinessNotificationsPageViewed(), options);
  }

  /**
   * Business Portal Entered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Business%20Portal%20Entered)
   *
   * When a user enters the portal and is not a multi portal
   *
   * Owner: Ryan Raj
   *
   * @param properties The event's properties (e.g. Business Id)
   * @param options Amplitude event options.
   */
  businessPortalEntered(
    properties: BusinessPortalEnteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new BusinessPortalEntered(properties), options);
  }

  /**
   * Business Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Business%20Selected)
   *
   * When a business is selected from a multi portal
   *
   * Owner: tnguyen@veryableops.com
   *
   * @param properties The event's properties (e.g. Business Id)
   * @param options Amplitude event options.
   */
  businessSelected(
    properties: BusinessSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BusinessSelected(properties), options);
  }

  /**
   * Business Switched
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Business%20Switched)
   *
   * When a user switches to a different Business portal
   *
   * Owner: tnguyen@veryableops.com
   *
   * @param options Amplitude event options.
   */
  businessSwitched(
    options?: EventOptions,
  ) {
    return this.track(new BusinessSwitched(), options);
  }

  /**
   * Cancel Op Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Cancel%20Op%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Op Id)
   * @param options Amplitude event options.
   */
  cancelOpClicked(
    properties: CancelOpClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelOpClicked(properties), options);
  }

  /**
   * Cancel Op Summary Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Cancel%20Op%20Summary%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Op Id)
   * @param options Amplitude event options.
   */
  cancelOpSummaryClicked(
    properties: CancelOpSummaryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelOpSummaryClicked(properties), options);
  }

  /**
   * Chat Message Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Chat%20Message%20Sent)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  chatMessageSent(
    options?: EventOptions,
  ) {
    return this.track(new ChatMessageSent(), options);
  }

  /**
   * Check In Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Check%20In%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Click Lead Time)
   * @param options Amplitude event options.
   */
  checkInButtonClicked(
    properties: CheckInButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckInButtonClicked(properties), options);
  }

  /**
   * Check Out Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Check%20Out%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Click Lead Time)
   * @param options Amplitude event options.
   */
  checkOutButtonClicked(
    properties: CheckOutButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckOutButtonClicked(properties), options);
  }

  /**
   * Completed Tab Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Completed%20Tab%20Clicked)
   *
   * When a User clicks on the Completed tab within the Ops page
   *
   * @param options Amplitude event options.
   */
  completedTabClicked(
    options?: EventOptions,
  ) {
    return this.track(new CompletedTabClicked(), options);
  }

  /**
   * Create Profile Source
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Create%20Profile%20Source)
   *
   * An event to track the 'How did you hear about us field' on the Create Profile Page
   *
   * @param properties The event's properties (e.g. Business Id)
   * @param options Amplitude event options.
   */
  createProfileSource(
    properties: CreateProfileSourceProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateProfileSource(properties), options);
  }

  /**
   * Dispute Reversed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Dispute%20Reversed)
   *
   * Owner: Lucas Nogueira
   *
   * @param properties The event's properties (e.g. Assignment Id)
   * @param options Amplitude event options.
   */
  disputeReversed(
    properties: DisputeReversedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DisputeReversed(properties), options);
  }

  /**
   * Do Not Pay Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Do%20Not%20Pay%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Clicked Via)
   * @param options Amplitude event options.
   */
  doNotPayButtonClicked(
    properties: DoNotPayButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DoNotPayButtonClicked(properties), options);
  }

  /**
   * Document Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Document%20Deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Document Name)
   * @param options Amplitude event options.
   */
  documentDeleted(
    properties: DocumentDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentDeleted(properties), options);
  }

  /**
   * Document Uploaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Document%20Uploaded)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Document Name)
   * @param options Amplitude event options.
   */
  documentUploaded(
    properties: DocumentUploadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentUploaded(properties), options);
  }

  /**
   * Edit Template Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Edit%20Template%20Button%20Clicked)
   *
   * When a User clicks on the Edit Template Button within the Post Op Button
   *
   * @param options Amplitude event options.
   */
  editTemplateButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new EditTemplateButtonClicked(), options);
  }

  /**
   * Expired Bids Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Expired%20Bids%20Viewed)
   *
   * When a User clicks on the Expired tab in the Bids table on the Op Details page
   *
   * @param options Amplitude event options.
   */
  expiredBidsViewed(
    options?: EventOptions,
  ) {
    return this.track(new ExpiredBidsViewed(), options);
  }

  /**
   * From Template Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/From%20Template%20Button%20Clicked)
   *
   * When a User clicks on the From Template Button within the Post Op Button
   *
   * @param options Amplitude event options.
   */
  fromTemplateButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new FromTemplateButtonClicked(), options);
  }

  /**
   * Gated Onboarding Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Gated%20Onboarding%20Completed)
   *
   * Triggered when the User clicks on "go to portal" after Gated Onboarding Flow
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param options Amplitude event options.
   */
  gatedOnboardingCompleted(
    options?: EventOptions,
  ) {
    return this.track(new GatedOnboardingCompleted(), options);
  }

  /**
   * Geofence Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Geofence%20Created)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Geofence Radius Size)
   * @param options Amplitude event options.
   */
  geofenceCreated(
    properties: GeofenceCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GeofenceCreated(properties), options);
  }

  /**
   * Geofence Map Cleared
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Geofence%20Map%20Cleared)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Geofence Type)
   * @param options Amplitude event options.
   */
  geofenceMapCleared(
    properties: GeofenceMapClearedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GeofenceMapCleared(properties), options);
  }

  /**
   * Geofence Previewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Geofence%20Previewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Geofence Type)
   * @param options Amplitude event options.
   */
  geofencePreviewed(
    properties: GeofencePreviewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GeofencePreviewed(properties), options);
  }

  /**
   * Geofence Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Geofence%20Updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Geofence Radius Size)
   * @param options Amplitude event options.
   */
  geofenceUpdated(
    properties: GeofenceUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GeofenceUpdated(properties), options);
  }

  /**
   * Hvd Adjustments Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Hvd%20Adjustments%20Filtered)
   *
   * When a User filters their Actionable or Historical adjustments within the HVD on the Adjustments Tab
   *
   * @param properties The event's properties (e.g. End Date)
   * @param options Amplitude event options.
   */
  hvdAdjustmentsFiltered(
    properties: HvdAdjustmentsFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new HvdAdjustmentsFiltered(properties), options);
  }

  /**
   * Hvd Filters Reset
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Hvd%20Filters%20Reset)
   *
   * When a User resets their HVD filters
   *
   * @param options Amplitude event options.
   */
  hvdFiltersReset(
    options?: EventOptions,
  ) {
    return this.track(new HvdFiltersReset(), options);
  }

  /**
   * Hvd Ops Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Hvd%20Ops%20Filtered)
   *
   * When a User filters their Ops within the HVD on the Ops Tab
   *
   * @param properties The event's properties (e.g. End Date)
   * @param options Amplitude event options.
   */
  hvdOpsFiltered(
    properties: HvdOpsFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new HvdOpsFiltered(properties), options);
  }

  /**
   * Hvd Ratings Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Hvd%20Ratings%20Filtered)
   *
   * When a User filters their Ratings within the HVD on the Ratings Tab
   *
   * @param properties The event's properties (e.g. End Date)
   * @param options Amplitude event options.
   */
  hvdRatingsFiltered(
    properties?: HvdRatingsFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new HvdRatingsFiltered(properties), options);
  }

  /**
   * Industry Gate Passed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Industry%20Gate%20Passed)
   *
   * Passed First Step of Gated Onboarding
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Industry)
   * @param options Amplitude event options.
   */
  industryGatePassed(
    properties: IndustryGatePassedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IndustryGatePassed(properties), options);
  }

  /**
   * Inline In Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Inline%20In%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Clicked Via)
   * @param options Amplitude event options.
   */
  inlineInButtonClicked(
    properties: InlineInButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InlineInButtonClicked(properties), options);
  }

  /**
   * Inline Out Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Inline%20Out%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Clicked Via)
   * @param options Amplitude event options.
   */
  inlineOutButtonClicked(
    properties: InlineOutButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InlineOutButtonClicked(properties), options);
  }

  /**
   * Invited Bids Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Invited%20Bids%20Viewed)
   *
   * When a User clicks on the Invited tab in the Bids table on the Op Details page
   *
   * @param options Amplitude event options.
   */
  invitedBidsViewed(
    options?: EventOptions,
  ) {
    return this.track(new InvitedBidsViewed(), options);
  }

  /**
   * Labor Pool Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Labor%20Pool%20Filtered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Labor Pool Tags Filter)
   * @param options Amplitude event options.
   */
  laborPoolFiltered(
    properties?: LaborPoolFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new LaborPoolFiltered(properties), options);
  }

  /**
   * Link Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Link%20Added)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Added Via)
   * @param options Amplitude event options.
   */
  linkAdded(
    properties: LinkAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LinkAdded(properties), options);
  }

  /**
   * Link Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Link%20Deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Link Name)
   * @param options Amplitude event options.
   */
  linkDeleted(
    properties: LinkDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LinkDeleted(properties), options);
  }

  /**
   * Link Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Link%20Edited)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Link Name)
   * @param options Amplitude event options.
   */
  linkEdited(
    properties: LinkEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LinkEdited(properties), options);
  }

  /**
   * Location Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Location%20Added)
   *
   * When a User adds a new Location
   *
   * @param properties The event's properties (e.g. Geofence Radius Size)
   * @param options Amplitude event options.
   */
  locationAdded(
    properties: LocationAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LocationAdded(properties), options);
  }

  /**
   * Location Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Location%20Deleted)
   *
   * When a marketplace user removed a location from their portal
   *
   * @param properties The event's properties (e.g. Location Name)
   * @param options Amplitude event options.
   */
  locationDeleted(
    properties: LocationDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LocationDeleted(properties), options);
  }

  /**
   * Location Flow Exited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Location%20Flow%20Exited)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Location Flow Step Number)
   * @param options Amplitude event options.
   */
  locationFlowExited(
    properties: LocationFlowExitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LocationFlowExited(properties), options);
  }

  /**
   * Location Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Location%20Updated)
   *
   * When a User updates a Location
   *
   * @param properties The event's properties (e.g. Geofence Radius Size)
   * @param options Amplitude event options.
   */
  locationUpdated(
    properties: LocationUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LocationUpdated(properties), options);
  }

  /**
   * Logged In
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Logged%20In)
   *
   * When a user logs in manually or via Google SSO
   *
   * Owner: tnguyen@veryableops.com
   *
   * @param properties The event's properties (e.g. Is Multi Portal Business User)
   * @param options Amplitude event options.
   */
  loggedIn(
    properties: LoggedInProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoggedIn(properties), options);
  }

  /**
   * Logo Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Logo%20Updated)
   *
   * When a User updates their logo
   *
   * @param properties The event's properties (e.g. Logo Type)
   * @param options Amplitude event options.
   */
  logoUpdated(
    properties: LogoUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LogoUpdated(properties), options);
  }

  /**
   * More Work Areas Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/More%20Work%20Areas%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  moreWorkAreasButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new MoreWorkAreasButtonClicked(), options);
  }

  /**
   * New Op Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/New%20Op%20Button%20Clicked)
   *
   * When a User clicks on the New Op Button within the Post Op Button
   *
   * @param options Amplitude event options.
   */
  newOpButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new NewOpButtonClicked(), options);
  }

  /**
   * Op Codes Sidebar Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Codes%20Sidebar%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Is Check In Code Required)
   * @param options Amplitude event options.
   */
  opCodesSidebarViewed(
    properties: OpCodesSidebarViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpCodesSidebarViewed(properties), options);
  }

  /**
   * Op Contact Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Contact%20Added)
   *
   * When an Op Contact is created from an Op or from the Users & Team Page
   *
   * @param properties The event's properties (e.g. Added Via)
   * @param options Amplitude event options.
   */
  opContactAdded(
    properties?: OpContactAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpContactAdded(properties), options);
  }

  /**
   * Op Contact Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Contact%20Deleted)
   *
   * When an Op Contact is deleted on the Users & Team Page
   *
   * @param options Amplitude event options.
   */
  opContactDeleted(
    options?: EventOptions,
  ) {
    return this.track(new OpContactDeleted(), options);
  }

  /**
   * Op Contact Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Contact%20Edited)
   *
   * When an Op Contact is edited on the Users & Team Page
   *
   * @param options Amplitude event options.
   */
  opContactEdited(
    options?: EventOptions,
  ) {
    return this.track(new OpContactEdited(), options);
  }

  /**
   * Op Day Adjusted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Day%20Adjusted)
   *
   * When a business user adjusts a bid in Op Detail or HVD Adjustments
   *
   * Owner: tnguyen@veryableops.com
   *
   * @param properties The event's properties (e.g. Adjusted Amount)
   * @param options Amplitude event options.
   */
  opDayAdjusted(
    properties: OpDayAdjustedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpDayAdjusted(properties), options);
  }

  /**
   * Op Day Cancelled
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Day%20Cancelled)
   *
   * When an Op Day gets cancelled during an active Assignment
   *
   * Owner: tnguyen@veryableops.com
   *
   * @param properties The event's properties (e.g. Assignment Id)
   * @param options Amplitude event options.
   */
  opDayCancelled(
    properties: OpDayCancelledProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpDayCancelled(properties), options);
  }

  /**
   * Op Day Disputed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Day%20Disputed)
   *
   * When an Op Day gets disputed during an active Assignment
   *
   * Owner: tnguyen@veryableops.com
   *
   * @param properties The event's properties (e.g. Assignment Id)
   * @param options Amplitude event options.
   */
  opDayDisputed(
    properties: OpDayDisputedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpDayDisputed(properties), options);
  }

  /**
   * Op Day Marked Absent
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Day%20Marked%20Absent)
   *
   * Owner: tnguyen@veryableops.com
   *
   * @param properties The event's properties (e.g. Assignment Id)
   * @param options Amplitude event options.
   */
  opDayMarkedAbsent(
    properties: OpDayMarkedAbsentProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpDayMarkedAbsent(properties), options);
  }

  /**
   * Op Deactivated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Deactivated)
   *
   * When user deactivates op
   *
   * Owner: ajohnson@veryableops.com
   *
   * @param properties The event's properties (e.g. Op Id)
   * @param options Amplitude event options.
   */
  opDeactivated(
    properties: OpDeactivatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpDeactivated(properties), options);
  }

  /**
   * Op Details Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Details%20Viewed)
   *
   * When a User clicks on the Op Details button within the kebab on HVD adjustments tab or when they click into an Op from the HVD or Ops tab
   *
   * @param properties The event's properties (e.g. Viewed Via)
   * @param options Amplitude event options.
   */
  opDetailsViewed(
    properties: OpDetailsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpDetailsViewed(properties), options);
  }

  /**
   * Op Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Edited)
   *
   * Owner: Sanket Naik
   *
   * @param properties The event's properties (e.g. Keep Bids)
   * @param options Amplitude event options.
   */
  opEdited(
    properties: OpEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpEdited(properties), options);
  }

  /**
   * Op Info Sidebar Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Info%20Sidebar%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Viewed Via)
   * @param options Amplitude event options.
   */
  opInfoSidebarViewed(
    properties: OpInfoSidebarViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpInfoSidebarViewed(properties), options);
  }

  /**
   * Op Posted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Posted)
   *
   * Includes Long Post, Quick Post, and Bulk Post
   *
   * Owner: tnguyen@veryableops.com
   *
   * @param properties The event's properties (e.g. Op Id)
   * @param options Amplitude event options.
   */
  opPosted(
    properties: OpPostedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpPosted(properties), options);
  }

  /**
   * Op Reactivated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Reactivated)
   *
   * Event tracking for reactivation of an Op
   *
   * Owner: ajohnson@veryableops.com
   *
   * @param properties The event's properties (e.g. Op Id)
   * @param options Amplitude event options.
   */
  opReactivated(
    properties: OpReactivatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpReactivated(properties), options);
  }

  /**
   * Op Scheduled
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Scheduled)
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Scheduled Op Id)
   * @param options Amplitude event options.
   */
  opScheduled(
    properties: OpScheduledProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpScheduled(properties), options);
  }

  /**
   * Op Template Collapsed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Template%20Collapsed)
   *
   * When User Collapses an Op Template on the Op Templates Tab within the Ops page
   *
   * @param options Amplitude event options.
   */
  opTemplateCollapsed(
    options?: EventOptions,
  ) {
    return this.track(new OpTemplateCollapsed(), options);
  }

  /**
   * Op Template Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Template%20Created)
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Attachments)
   * @param options Amplitude event options.
   */
  opTemplateCreated(
    properties: OpTemplateCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpTemplateCreated(properties), options);
  }

  /**
   * Op Template Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Template%20Deleted)
   *
   * When User Deletes an Op Template on the Op Templates Tab within the Ops page
   *
   * @param options Amplitude event options.
   */
  opTemplateDeleted(
    options?: EventOptions,
  ) {
    return this.track(new OpTemplateDeleted(), options);
  }

  /**
   * Op Template Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Template%20Edited)
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Attachments)
   * @param options Amplitude event options.
   */
  opTemplateEdited(
    properties: OpTemplateEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpTemplateEdited(properties), options);
  }

  /**
   * Op Template Expanded
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Template%20Expanded)
   *
   * When User Expands an Op Template on the Op Templates Tab within the Ops page
   *
   * @param options Amplitude event options.
   */
  opTemplateExpanded(
    options?: EventOptions,
  ) {
    return this.track(new OpTemplateExpanded(), options);
  }

  /**
   * Op Templates Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Op%20Templates%20Button%20Clicked)
   *
   * When a User clicks on the Op Templates tab within the Ops page
   *
   * @param options Amplitude event options.
   */
  opTemplatesButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new OpTemplatesButtonClicked(), options);
  }

  /**
   * Operator Added to Backup
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Added%20to%20Backup)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Added Via)
   * @param options Amplitude event options.
   */
  operatorAddedToBackup(
    properties: OperatorAddedToBackupProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorAddedToBackup(properties), options);
  }

  /**
   * Operator Added To Labor Pool
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Added%20To%20Labor%20Pool)
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Added To Labor Pool Via)
   * @param options Amplitude event options.
   */
  operatorAddedToLaborPool(
    properties: OperatorAddedToLaborPoolProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorAddedToLaborPool(properties), options);
  }

  /**
   * Operator Blocked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Blocked)
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Blocked Via)
   * @param options Amplitude event options.
   */
  operatorBlocked(
    properties: OperatorBlockedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorBlocked(properties), options);
  }

  /**
   * Operator Endorsed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Endorsed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Endorsed Via)
   * @param options Amplitude event options.
   */
  operatorEndorsed(
    properties: OperatorEndorsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorEndorsed(properties), options);
  }

  /**
   * Operator Invited To Op
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Invited%20To%20Op)
   *
   * When a User invites an Operator to an Op individually or when they invite their Labor Pool
   *
   * @param properties The event's properties (e.g. Operator Invited Via)
   * @param options Amplitude event options.
   */
  operatorInvitedToOp(
    properties: OperatorInvitedToOpProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorInvitedToOp(properties), options);
  }

  /**
   * Operator Rated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Rated)
   *
   * Owner: mchan@veryableops.com
   *
   * @param properties The event's properties (e.g. Assignment Id)
   * @param options Amplitude event options.
   */
  operatorRated(
    properties: OperatorRatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorRated(properties), options);
  }

  /**
   * Operator Removed From Backup
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Removed%20From%20Backup)
   *
   * When a business removed an operator from the backup list
   *
   * @param properties The event's properties (e.g. Business Id)
   * @param options Amplitude event options.
   */
  operatorRemovedFromBackup(
    properties?: OperatorRemovedFromBackupProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorRemovedFromBackup(properties), options);
  }

  /**
   * Operator Removed From Labor Pool
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Removed%20From%20Labor%20Pool)
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Business Id)
   * @param options Amplitude event options.
   */
  operatorRemovedFromLaborPool(
    properties: OperatorRemovedFromLaborPoolProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorRemovedFromLaborPool(properties), options);
  }

  /**
   * Operator Removed From Op
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Removed%20From%20Op)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Assignment Id)
   * @param options Amplitude event options.
   */
  operatorRemovedFromOp(
    properties: OperatorRemovedFromOpProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorRemovedFromOp(properties), options);
  }

  /**
   * Operator Resume Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Resume%20Viewed)
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Operator Id)
   * @param options Amplitude event options.
   */
  operatorResumeViewed(
    properties: OperatorResumeViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorResumeViewed(properties), options);
  }

  /**
   * Operator Tag Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Tag%20Added)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Business Id)
   * @param options Amplitude event options.
   */
  operatorTagAdded(
    properties: OperatorTagAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorTagAdded(properties), options);
  }

  /**
   * Operator Tag Removed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Tag%20Removed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Business Id)
   * @param options Amplitude event options.
   */
  operatorTagRemoved(
    properties: OperatorTagRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorTagRemoved(properties), options);
  }

  /**
   * Operator Trained Work Area Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Trained%20Work%20Area%20Added)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Operator Id)
   * @param options Amplitude event options.
   */
  operatorTrainedWorkAreaAdded(
    properties: OperatorTrainedWorkAreaAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorTrainedWorkAreaAdded(properties), options);
  }

  /**
   * Operator Trained Work Area Removed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Trained%20Work%20Area%20Removed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Operator Id)
   * @param options Amplitude event options.
   */
  operatorTrainedWorkAreaRemoved(
    properties: OperatorTrainedWorkAreaRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorTrainedWorkAreaRemoved(properties), options);
  }

  /**
   * Operator Unblocked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operator%20Unblocked)
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Business Id)
   * @param options Amplitude event options.
   */
  operatorUnblocked(
    properties: OperatorUnblockedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorUnblocked(properties), options);
  }

  /**
   * Operators Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Operators%20Exported)
   *
   * When a User exports the visible Operators
   *
   * @param properties The event's properties (e.g. Exported Via)
   * @param options Amplitude event options.
   */
  operatorsExported(
    properties: OperatorsExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OperatorsExported(properties), options);
  }

  /**
   * Ops Added To Summary
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Ops%20Added%20To%20Summary)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Op Type)
   * @param options Amplitude event options.
   */
  opsAddedToSummary(
    properties: OpsAddedToSummaryProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpsAddedToSummary(properties), options);
  }

  /**
   * Ops Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Ops%20Exported)
   *
   * When a User exports the visible Ops
   *
   * @param properties The event's properties (e.g. Exported Via)
   * @param options Amplitude event options.
   */
  opsExported(
    properties: OpsExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpsExported(properties), options);
  }

  /**
   * Ops Summary Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Ops%20Summary%20Filtered)
   *
   * When a User filters their Ops within the HVD on the Summary Tab
   *
   * @param properties The event's properties (e.g. End Date)
   * @param options Amplitude event options.
   */
  opsSummaryFiltered(
    properties?: OpsSummaryFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpsSummaryFiltered(properties), options);
  }

  /**
   * Pay Now Banner Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Pay%20Now%20Banner%20Clicked)
   *
   * When a User clicks on the Pay Now button on the Action Required Banner
   *
   * @param options Amplitude event options.
   */
  payNowBannerClicked(
    options?: EventOptions,
  ) {
    return this.track(new PayNowBannerClicked(), options);
  }

  /**
   * Pay Restored
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Pay%20Restored)
   *
   * When an Op Day is marked absent and the business user restores the pay
   *
   * Owner: tnguyen@veryableops.com
   *
   * @param properties The event's properties (e.g. Assignment Id)
   * @param options Amplitude event options.
   */
  payRestored(
    properties: PayRestoredProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayRestored(properties), options);
  }

  /**
   * Payment Method Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Payment%20Method%20Updated)
   *
   * When a payment method is added or updated
   *
   * Owner: tnguyen@veryableops.com
   *
   * @param properties The event's properties (e.g. Business Payment Type)
   * @param options Amplitude event options.
   */
  paymentMethodUpdated(
    properties?: PaymentMethodUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentMethodUpdated(properties), options);
  }

  /**
   * Portal Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Portal%20Created)
   *
   * When a Business Portal is created
   *
   * Owner: tnguyen@veryableops.com
   *
   * @param properties The event's properties (e.g. Business Id)
   * @param options Amplitude event options.
   */
  portalCreated(
    properties?: PortalCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PortalCreated(properties), options);
  }

  /**
   * Post Op Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Post%20Op%20Button%20Clicked)
   *
   * When a User clicks on the Post Op Button
   *
   * @param options Amplitude event options.
   */
  postOpButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new PostOpButtonClicked(), options);
  }

  /**
   * Ramp Plan Confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Ramp%20Plan%20Confirmed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Current Labor Pool)
   * @param options Amplitude event options.
   */
  rampPlanConfirmed(
    properties: RampPlanConfirmedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RampPlanConfirmed(properties), options);
  }

  /**
   * Ramp Plan Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Ramp%20Plan%20Edited)
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Edited Via)
   * @param options Amplitude event options.
   */
  rampPlanEdited(
    properties: RampPlanEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RampPlanEdited(properties), options);
  }

  /**
   * Ramp Plan Settings Icon Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Ramp%20Plan%20Settings%20Icon%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  rampPlanSettingsIconClicked(
    options?: EventOptions,
  ) {
    return this.track(new RampPlanSettingsIconClicked(), options);
  }

  /**
   * Ramp Plan Settings Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Ramp%20Plan%20Settings%20Updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Demand Cycle)
   * @param options Amplitude event options.
   */
  rampPlanSettingsUpdated(
    properties: RampPlanSettingsUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RampPlanSettingsUpdated(properties), options);
  }

  /**
   * Ready To Work Bids Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Ready%20To%20Work%20Bids%20Viewed)
   *
   * When a User clicks on the Ready to Work tab in the Bids table on the Op Details page
   *
   * @param options Amplitude event options.
   */
  readyToWorkBidsViewed(
    options?: EventOptions,
  ) {
    return this.track(new ReadyToWorkBidsViewed(), options);
  }

  /**
   * Restore Pay Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Restore%20Pay%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  restorePayButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new RestorePayButtonClicked(), options);
  }

  /**
   * Resume Certifications Tab Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Resume%20Certifications%20Tab%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Viewed Via)
   * @param options Amplitude event options.
   */
  resumeCertificationsTabViewed(
    properties: ResumeCertificationsTabViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResumeCertificationsTabViewed(properties), options);
  }

  /**
   * Resume Endorsements Tab Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Resume%20Endorsements%20Tab%20Viewed)
   *
   * When a business clicks on the Endorsements tab on an Operator Resume
   *
   * @param properties The event's properties (e.g. Operator Id)
   * @param options Amplitude event options.
   */
  resumeEndorsementsTabViewed(
    properties: ResumeEndorsementsTabViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResumeEndorsementsTabViewed(properties), options);
  }

  /**
   * Resume Milestones Tab Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Resume%20Milestones%20Tab%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Viewed Via)
   * @param options Amplitude event options.
   */
  resumeMilestonesTabViewed(
    properties: ResumeMilestonesTabViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResumeMilestonesTabViewed(properties), options);
  }

  /**
   * Resume Overview Tab Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Resume%20Overview%20Tab%20Viewed)
   *
   * Event to track when a business user views the resume overview tab.
   *
   * @param properties The event's properties (e.g. Viewed Via)
   * @param options Amplitude event options.
   */
  resumeOverviewTabViewed(
    properties: ResumeOverviewTabViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResumeOverviewTabViewed(properties), options);
  }

  /**
   * Resume Skills & Experience Tab Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Resume%20Skills%20%26%20Experience%20Tab%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Viewed Via)
   * @param options Amplitude event options.
   */
  resumeSkillsExperienceTabViewed(
    properties: ResumeSkillsExperienceTabViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResumeSkillsExperienceTabViewed(properties), options);
  }

  /**
   * Roster Date Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Roster%20Date%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Date Offset)
   * @param options Amplitude event options.
   */
  rosterDateSelected(
    properties?: RosterDateSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RosterDateSelected(properties), options);
  }

  /**
   * Roster Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Roster%20Exported)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Roster View)
   * @param options Amplitude event options.
   */
  rosterExported(
    properties: RosterExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RosterExported(properties), options);
  }

  /**
   * Roster Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Roster%20Filtered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Location Filter)
   * @param options Amplitude event options.
   */
  rosterFiltered(
    properties?: RosterFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new RosterFiltered(properties), options);
  }

  /**
   * Roster Filters Reset
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Roster%20Filters%20Reset)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Roster View)
   * @param options Amplitude event options.
   */
  rosterFiltersReset(
    properties?: RosterFiltersResetProperties,
    options?: EventOptions,
  ) {
    return this.track(new RosterFiltersReset(properties), options);
  }

  /**
   * Roster Op Timeline Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Roster%20Op%20Timeline%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Op Id)
   * @param options Amplitude event options.
   */
  rosterOpTimelineClicked(
    properties: RosterOpTimelineClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RosterOpTimelineClicked(properties), options);
  }

  /**
   * Roster Settings Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Roster%20Settings%20Updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Is Check In Code Required)
   * @param options Amplitude event options.
   */
  rosterSettingsUpdated(
    properties?: RosterSettingsUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RosterSettingsUpdated(properties), options);
  }

  /**
   * Roster View Settings Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Roster%20View%20Settings%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  rosterViewSettingsButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new RosterViewSettingsButtonClicked(), options);
  }

  /**
   * Roster View Settings Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Roster%20View%20Settings%20Updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Is Hide Op Timeline)
   * @param options Amplitude event options.
   */
  rosterViewSettingsUpdated(
    properties?: RosterViewSettingsUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RosterViewSettingsUpdated(properties), options);
  }

  /**
   * Roster View Toggled
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Roster%20View%20Toggled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Roster View)
   * @param options Amplitude event options.
   */
  rosterViewToggled(
    properties: RosterViewToggledProperties,
    options?: EventOptions,
  ) {
    return this.track(new RosterViewToggled(properties), options);
  }

  /**
   * Schedule Conflict Bids Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Schedule%20Conflict%20Bids%20Viewed)
   *
   * When a User clicks on the Schedule Conflict tab in the Bids table on the Op Details page
   *
   * @param options Amplitude event options.
   */
  scheduleConflictBidsViewed(
    options?: EventOptions,
  ) {
    return this.track(new ScheduleConflictBidsViewed(), options);
  }

  /**
   * Send Message Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Send%20Message%20Clicked)
   *
   * When the user clicks the "Send Message" button in WFM
   *
   * @param options Amplitude event options.
   */
  sendMessageClicked(
    options?: EventOptions,
  ) {
    return this.track(new SendMessageClicked(), options);
  }

  /**
   * Settings Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Settings%20Button%20Clicked)
   *
   * When a User clicks on the Settings Button (Gear Icon)
   *
   * @param options Amplitude event options.
   */
  settingsButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new SettingsButtonClicked(), options);
  }

  /**
   * Show Alerts Toggled
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Show%20Alerts%20Toggled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Is Hidden)
   * @param options Amplitude event options.
   */
  showAlertsToggled(
    properties: ShowAlertsToggledProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShowAlertsToggled(properties), options);
  }

  /**
   * Show Disputes Toggled
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Show%20Disputes%20Toggled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Is Toggled On)
   * @param options Amplitude event options.
   */
  showDisputesToggled(
    properties: ShowDisputesToggledProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShowDisputesToggled(properties), options);
  }

  /**
   * Show Operators Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Show%20Operators%20Button%20Clicked)
   *
   * When a User clicks on the button to switch from Ops to Operator view on the HVD or the Ops Tab
   *
   * @param properties The event's properties (e.g. Clicked Via)
   * @param options Amplitude event options.
   */
  showOperatorsButtonClicked(
    properties: ShowOperatorsButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShowOperatorsButtonClicked(properties), options);
  }

  /**
   * Show Ops Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Show%20Ops%20Button%20Clicked)
   *
   * When a User clicks on the button to switch from Operators to Ops view on the HVD or Ops Tab
   *
   * @param properties The event's properties (e.g. Clicked Via)
   * @param options Amplitude event options.
   */
  showOpsButtonClicked(
    properties: ShowOpsButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShowOpsButtonClicked(properties), options);
  }

  /**
   * Start Rating Banner Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Start%20Rating%20Banner%20Clicked)
   *
   * This event tracks when a user clicks on the button within the "Start Rating" banner on the mobile and web apps
   *
   * @param options Amplitude event options.
   */
  startRatingBannerClicked(
    options?: EventOptions,
  ) {
    return this.track(new StartRatingBannerClicked(), options);
  }

  /**
   * Suggested Operators Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Suggested%20Operators%20Filtered)
   *
   * When a User filters the suggested Operators table in the Op Details page
   *
   * @param properties The event's properties (e.g. Badge Filter)
   * @param options Amplitude event options.
   */
  suggestedOperatorsFiltered(
    properties?: SuggestedOperatorsFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new SuggestedOperatorsFiltered(properties), options);
  }

  /**
   * Tag Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Tag%20Created)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Business Id)
   * @param options Amplitude event options.
   */
  tagCreated(
    properties: TagCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TagCreated(properties), options);
  }

  /**
   * Tag Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Tag%20Deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Business Id)
   * @param options Amplitude event options.
   */
  tagDeleted(
    properties: TagDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TagDeleted(properties), options);
  }

  /**
   * Tag Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Tag%20Edited)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Business Id)
   * @param options Amplitude event options.
   */
  tagEdited(
    properties: TagEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TagEdited(properties), options);
  }

  /**
   * Tags Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Tags%20Button%20Clicked)
   *
   * When a User clicks on the YLP Tags Button
   *
   * @param properties The event's properties (e.g. Tag Action)
   * @param options Amplitude event options.
   */
  tagsButtonClicked(
    properties?: TagsButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TagsButtonClicked(properties), options);
  }

  /**
   * Time-Off Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Time-Off%20Added)
   *
   * When a user opens employee details and clicks the + button to add time off to the employee
   *
   * @param options Amplitude event options.
   */
  timeOffAdded(
    options?: EventOptions,
  ) {
    return this.track(new TimeOffAdded(), options);
  }

  /**
   * Trained Operators Edit Icon Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Trained%20Operators%20Edit%20Icon%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  trainedOperatorsEditIconClicked(
    options?: EventOptions,
  ) {
    return this.track(new TrainedOperatorsEditIconClicked(), options);
  }

  /**
   * Upcoming Tab Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Upcoming%20Tab%20Clicked)
   *
   * When a User clicks on the Upcoming tab within the Ops page
   *
   * @param options Amplitude event options.
   */
  upcomingTabClicked(
    options?: EventOptions,
  ) {
    return this.track(new UpcomingTabClicked(), options);
  }

  /**
   * User Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/User%20Deleted)
   *
   * Administrator deletes a different User
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Deleted Via)
   * @param options Amplitude event options.
   */
  userDeleted(
    properties: UserDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserDeleted(properties), options);
  }

  /**
   * User Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/User%20Edited)
   *
   * User Edits one of the available fields for a different or their own User account
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Edited Via)
   * @param options Amplitude event options.
   */
  userEdited(
    properties?: UserEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserEdited(properties), options);
  }

  /**
   * User Invite Gate Passed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/User%20Invite%20Gate%20Passed)
   *
   * Passed third step of Gated Onboarding. Triggered by both the I'll do this later or Next buttons.
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Number of Users)
   * @param options Amplitude event options.
   */
  userInviteGatePassed(
    properties: UserInviteGatePassedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserInviteGatePassed(properties), options);
  }

  /**
   * User Invite Resent
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/User%20Invite%20Resent)
   *
   * When a User has a pending invite resent
   *
   * @param options Amplitude event options.
   */
  userInviteResent(
    options?: EventOptions,
  ) {
    return this.track(new UserInviteResent(), options);
  }

  /**
   * User Invited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/User%20Invited)
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Invitee Locations)
   * @param options Amplitude event options.
   */
  userInvited(
    properties: UserInvitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserInvited(properties), options);
  }

  /**
   * WFM Actual Throughput Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Actual%20Throughput%20Edited)
   *
   * When a user changes the value for an actual throughput
   *
   * @param properties The event's properties (e.g. Column Edited)
   * @param options Amplitude event options.
   */
  wfmActualThroughputEdited(
    properties: WfmActualThroughputEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmActualThroughputEdited(properties), options);
  }

  /**
   * WFM Add Employees Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Add%20Employees%20Clicked)
   *
   * When a user clicks the add employees button on a child shift
   *
   * @param properties The event's properties (e.g. Employee Added)
   * @param options Amplitude event options.
   */
  wfmAddEmployeesClicked(
    properties?: WfmAddEmployeesClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmAddEmployeesClicked(properties), options);
  }

  /**
   * WFM Analytics Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Analytics%20Viewed)
   *
   * The user clicks the analytics tab in WFM
   *
   * @param options Amplitude event options.
   */
  wfmAnalyticsViewed(
    options?: EventOptions,
  ) {
    return this.track(new WfmAnalyticsViewed(), options);
  }

  /**
   * WFM Assignment Schedule Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Assignment%20Schedule%20Exported)
   *
   * User exports the CSV schedule in the summary page by clicking down arrow icon for the 'export CSV Schedule' button
   *
   * @param options Amplitude event options.
   */
  wfmAssignmentScheduleExported(
    options?: EventOptions,
  ) {
    return this.track(new WfmAssignmentScheduleExported(), options);
  }

  /**
   * WFM Assignment Subtype Applied
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Assignment%20Subtype%20Applied)
   *
   * When a user clicks a scheduled employee in a work area date slideout and selects an action that modifies the assignment sub type
   *
   * @param properties The event's properties (e.g. Assignment Subtype)
   * @param options Amplitude event options.
   */
  wfmAssignmentSubtypeApplied(
    properties: WfmAssignmentSubtypeAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmAssignmentSubtypeApplied(properties), options);
  }

  /**
   * WFM Data Synced
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Data%20Synced)
   *
   * When a user clicks the sync now button on any tab in WFM except employee tab (not in view)
   *
   * @param options Amplitude event options.
   */
  wfmDataSynced(
    options?: EventOptions,
  ) {
    return this.track(new WfmDataSynced(), options);
  }

  /**
   * WFM Date Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Date%20Changed)
   *
   * When a user clicks either of the arrows next to the week date range at the top of the summary page in WFM
   *
   * @param options Amplitude event options.
   */
  wfmDateChanged(
    options?: EventOptions,
  ) {
    return this.track(new WfmDateChanged(), options);
  }

  /**
   * WFM Day View Date Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Day%20View%20Date%20Changed)
   *
   * **Indicates the date the user is viewing. This will help understand the timeframe users are planning for**
   *
   * @param properties The event's properties (e.g. Schedule Date)
   * @param options Amplitude event options.
   */
  wfmDayViewDateChanged(
    properties: WfmDayViewDateChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmDayViewDateChanged(properties), options);
  }

  /**
   * WFM Day View Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Day%20View%20Filtered)
   *
   * **Indicates all of the filters that the user applies to view the day view**
   *
   * @param properties The event's properties (e.g. Filtered By)
   * @param options Amplitude event options.
   */
  wfmDayViewFiltered(
    properties: WfmDayViewFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmDayViewFiltered(properties), options);
  }

  /**
   * WFM Day View Schedule Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Day%20View%20Schedule%20Exported)
   *
   * **Indicates the date the user exports the day view schedule for. This will help understand how far out users are planning for and if using reports.**
   *
   * @param properties The event's properties (e.g. Schedule Date)
   * @param options Amplitude event options.
   */
  wfmDayViewScheduleExported(
    properties: WfmDayViewScheduleExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmDayViewScheduleExported(properties), options);
  }

  /**
   * WFM Department Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Department%20Added)
   *
   * When a user adds a Department in WFM
   *
   * @param options Amplitude event options.
   */
  wfmDepartmentAdded(
    options?: EventOptions,
  ) {
    return this.track(new WfmDepartmentAdded(), options);
  }

  /**
   * WFM Department Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Department%20Deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Department ID)
   * @param options Amplitude event options.
   */
  wfmDepartmentDeleted(
    properties: WfmDepartmentDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmDepartmentDeleted(properties), options);
  }

  /**
   * WFM Departments Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Departments%20Edited)
   *
   * When a user clicks the Save button at the bottom of the work areas and departments slideout
   *
   * @param properties The event's properties (e.g. Department ID)
   * @param options Amplitude event options.
   */
  wfmDepartmentsEdited(
    properties: WfmDepartmentsEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmDepartmentsEdited(properties), options);
  }

  /**
   * WFM Departments Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Departments%20Updated)
   *
   * An event for tracking when the departments are updated (added, edited or deleted)
   *
   * @param options Amplitude event options.
   */
  wfmDepartmentsUpdated(
    options?: EventOptions,
  ) {
    return this.track(new WfmDepartmentsUpdated(), options);
  }

  /**
   * WFM Employee Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Employee%20Edited)
   *
   * When a user clicks the orange remove employees button in the upper right
   *
   * @param options Amplitude event options.
   */
  wfmEmployeeEdited(
    options?: EventOptions,
  ) {
    return this.track(new WfmEmployeeEdited(), options);
  }

  /**
   * WFM Employee Manually Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Employee%20Manually%20Added)
   *
   * When user clicks the icon in the top right of the employe page with a plus and silhouette to manually add an employee
   *
   * @param options Amplitude event options.
   */
  wfmEmployeeManuallyAdded(
    options?: EventOptions,
  ) {
    return this.track(new WfmEmployeeManuallyAdded(), options);
  }

  /**
   * WFM Employee Master Schedule Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Employee%20Master%20Schedule%20Exported)
   *
   * When a user exports the master schedule from employee tab (different than CSV export on WFM summary)
   *
   * @param properties The event's properties (e.g. Schedule End Date)
   * @param options Amplitude event options.
   */
  wfmEmployeeMasterScheduleExported(
    properties: WfmEmployeeMasterScheduleExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmEmployeeMasterScheduleExported(properties), options);
  }

  /**
   * WFM Employee Reassigned
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Employee%20Reassigned)
   *
   * **Indicates that a user reassigned an employee, and will indicate details of the schedule move performed. This will help understand how users are scheduling and if they are adhering to the WFM labor planning concept**
   *
   * @param properties The event's properties (e.g. Is From Day View)
   * @param options Amplitude event options.
   */
  wfmEmployeeReassigned(
    properties: WfmEmployeeReassignedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmEmployeeReassigned(properties), options);
  }

  /**
   * WFM Employee Removed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Employee%20Removed)
   *
   * When a user clicks the orange remove employees button in the upper right
   *
   * @param options Amplitude event options.
   */
  wfmEmployeeRemoved(
    options?: EventOptions,
  ) {
    return this.track(new WfmEmployeeRemoved(), options);
  }

  /**
   * WFM Employee Tab Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Employee%20Tab%20Viewed)
   *
   * When a WFM user clicks the Employee Tab from any view in WFM
   *
   * @param options Amplitude event options.
   */
  wfmEmployeeTabViewed(
    options?: EventOptions,
  ) {
    return this.track(new WfmEmployeeTabViewed(), options);
  }

  /**
   * WFM Employee Unassigned
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Employee%20Unassigned)
   *
   * **Indicates the assignment removed and from which view**
   *
   * @param properties The event's properties (e.g. Employee Name)
   * @param options Amplitude event options.
   */
  wfmEmployeeUnassigned(
    properties: WfmEmployeeUnassignedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmEmployeeUnassigned(properties), options);
  }

  /**
   * WFM Employees Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Employees%20Filtered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Primary Work Area)
   * @param options Amplitude event options.
   */
  wfmEmployeesFiltered(
    properties?: WfmEmployeesFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmEmployeesFiltered(properties), options);
  }

  /**
   * WFM Forecasted Throughput Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Forecasted%20Throughput%20Edited)
   *
   * When a User edits a value for forecasted throughput from either the summary page by clicking the carrot or the processing qty table by clicking into an input box on processing qty table
   *
   * @param properties The event's properties (e.g. Edited From)
   * @param options Amplitude event options.
   */
  wfmForecastedThroughputEdited(
    properties?: WfmForecastedThroughputEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmForecastedThroughputEdited(properties), options);
  }

  /**
   * WFM FTE Comms Message Failure
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20FTE%20Comms%20Message%20Failure)
   *
   * When a message fails to add to the queue for messages to send to the FTE
   *
   * @param properties The event's properties (e.g. Employee Name)
   * @param options Amplitude event options.
   */
  wfmFteCommsMessageFailure(
    properties?: WfmFteCommsMessageFailureProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmFteCommsMessageFailure(properties), options);
  }

  /**
   * WFM FTE Comms Message Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20FTE%20Comms%20Message%20Sent)
   *
   * When a user clicks to attempt a message send throught the FTE Comms modal - each attempted recipient will count as a message
   *
   * @param properties The event's properties (e.g. Message Created Date)
   * @param options Amplitude event options.
   */
  wfmFteCommsMessageSent(
    properties: WfmFteCommsMessageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmFteCommsMessageSent(properties), options);
  }

  /**
   * WFM FTE Comms Offer Accepted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20FTE%20Comms%20Offer%20Accepted)
   *
   * When an FTE accepts a VTI or VTO offer
   *
   * @param options Amplitude event options.
   */
  wfmFteCommsOfferAccepted(
    options?: EventOptions,
  ) {
    return this.track(new WfmFteCommsOfferAccepted(), options);
  }

  /**
   * WFM Location Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Location%20Clicked)
   *
   * When a user selects the location drop down from any tab in WFM
   *
   * @param options Amplitude event options.
   */
  wfmLocationClicked(
    options?: EventOptions,
  ) {
    return this.track(new WfmLocationClicked(), options);
  }

  /**
   * WFM Needed Employees Adjusted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Needed%20Employees%20Adjusted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Adjustment)
   * @param options Amplitude event options.
   */
  wfmNeededEmployeesAdjusted(
    properties: WfmNeededEmployeesAdjustedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmNeededEmployeesAdjusted(properties), options);
  }

  /**
   * WFM Processing Quantity Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Processing%20Quantity%20Edited)
   *
   * When a user applies any of the filters to the processing quantity table
   *
   * @param properties The event's properties (e.g. Actual Demand After)
   * @param options Amplitude event options.
   */
  wfmProcessingQuantityEdited(
    properties: WfmProcessingQuantityEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmProcessingQuantityEdited(properties), options);
  }

  /**
   * WFM Processing Quantity Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Processing%20Quantity%20Exported)
   *
   * When a user clicks purple button in the slideout for "export processing quantity"
   *
   * @param options Amplitude event options.
   */
  wfmProcessingQuantityExported(
    options?: EventOptions,
  ) {
    return this.track(new WfmProcessingQuantityExported(), options);
  }

  /**
   * WFM Processing Quantity Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Processing%20Quantity%20Filtered)
   *
   * When a user applies any of the filters to the processing quantity table
   *
   * @param properties The event's properties (e.g. Date Range)
   * @param options Amplitude event options.
   */
  wfmProcessingQuantityFiltered(
    properties?: WfmProcessingQuantityFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmProcessingQuantityFiltered(properties), options);
  }

  /**
   * WFM Processing Quantity Uploaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Processing%20Quantity%20Uploaded)
   *
   * When a user clicks blue button in the slideout for "Upload processing quantity"
   *
   * @param options Amplitude event options.
   */
  wfmProcessingQuantityUploaded(
    options?: EventOptions,
  ) {
    return this.track(new WfmProcessingQuantityUploaded(), options);
  }

  /**
   * WFM Processing Quantity Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Processing%20Quantity%20Viewed)
   *
   * When a user clicks the processing quantity tab from any view in WFM
   *
   * @param options Amplitude event options.
   */
  wfmProcessingQuantityViewed(
    options?: EventOptions,
  ) {
    return this.track(new WfmProcessingQuantityViewed(), options);
  }

  /**
   * WFM Product Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Product%20Created)
   *
   * When a user clicks the "Add" button at the bottom of the slideout from clicking 'add new product' card under 'Products' Section
   *
   * @param options Amplitude event options.
   */
  wfmProductCreated(
    options?: EventOptions,
  ) {
    return this.track(new WfmProductCreated(), options);
  }

  /**
   * WFM Product Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Product%20Deleted)
   *
   * When a user deletes a product from the Products & Routings page in WFM
   *
   * @param properties The event's properties (e.g. Product ID)
   * @param options Amplitude event options.
   */
  wfmProductDeleted(
    properties: WfmProductDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmProductDeleted(properties), options);
  }

  /**
   * WFM Product Details Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Product%20Details%20Viewed)
   *
   * When a user clicks the view details blue text on a product card to open the product details slideout
   *
   * @param options Amplitude event options.
   */
  wfmProductDetailsViewed(
    options?: EventOptions,
  ) {
    return this.track(new WfmProductDetailsViewed(), options);
  }

  /**
   * WFM Product Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Product%20Edited)
   *
   * When a user clicks the blue 'apply' button on the bottom of the product details slideout.
   *
   * @param properties The event's properties (e.g. Average Percent of Volume)
   * @param options Amplitude event options.
   */
  wfmProductEdited(
    properties: WfmProductEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmProductEdited(properties), options);
  }

  /**
   * WFM Product Searched
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Product%20Searched)
   *
   * When a user clicks into the search bar on the products and routings tab
   *
   * @param options Amplitude event options.
   */
  wfmProductSearched(
    options?: EventOptions,
  ) {
    return this.track(new WfmProductSearched(), options);
  }

  /**
   * WFM Products & Routings Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Products%20%26%20Routings%20Viewed)
   *
   * When a WFM user clicks the Products and Routings Tab from any view in WFM
   *
   * @param options Amplitude event options.
   */
  wfmProductsRoutingsViewed(
    options?: EventOptions,
  ) {
    return this.track(new WfmProductsRoutingsViewed(), options);
  }

  /**
   * WFM PTO Applied
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20PTO%20Applied)
   *
   * **Indicates PTO applied to employee and from day or week view**
   *
   * @param properties The event's properties (e.g. Employee Name)
   * @param options Amplitude event options.
   */
  wfmPtoApplied(
    properties: WfmPtoAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmPtoApplied(properties), options);
  }

  /**
   * WFM Roster Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Roster%20Exported)
   *
   * When a user clicks the 'export CSV' button in the upper right of the employee table
   *
   * @param options Amplitude event options.
   */
  wfmRosterExported(
    options?: EventOptions,
  ) {
    return this.track(new WfmRosterExported(), options);
  }

  /**
   * WFM Roster Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Roster%20Filtered)
   *
   * When a user applies a filtered view to the employee roster
   *
   * @param properties The event's properties (e.g. Child Shift)
   * @param options Amplitude event options.
   */
  wfmRosterFiltered(
    properties: WfmRosterFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmRosterFiltered(properties), options);
  }

  /**
   * WFM Roster Uploaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Roster%20Uploaded)
   *
   * When a user clicks the upload roster button on the employee tab
   *
   * @param options Amplitude event options.
   */
  wfmRosterUploaded(
    options?: EventOptions,
  ) {
    return this.track(new WfmRosterUploaded(), options);
  }

  /**
   * WFM Schedule Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Schedule%20Filtered)
   *
   * Shift Category filter clicked in the WFM summary page
   *
   * @param properties The event's properties (e.g. Shift Category)
   * @param options Amplitude event options.
   */
  wfmScheduleFiltered(
    properties?: WfmScheduleFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmScheduleFiltered(properties), options);
  }

  /**
   * WFM Settings Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Settings%20Clicked)
   *
   * When a user clicks on the settings gear in WFM
   *
   * @param options Amplitude event options.
   */
  wfmSettingsClicked(
    options?: EventOptions,
  ) {
    return this.track(new WfmSettingsClicked(), options);
  }

  /**
   * WFM Settings Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Settings%20Updated)
   *
   * When a user clicks "Apply" after changing settings in WFM
   *
   * @param options Amplitude event options.
   */
  wfmSettingsUpdated(
    options?: EventOptions,
  ) {
    return this.track(new WfmSettingsUpdated(), options);
  }

  /**
   * WFM Shift Category Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Shift%20Category%20Created)
   *
   * When a user is in the purple 'manage shifts' button slideout, and clicks to create a child shift
   *
   * @param options Amplitude event options.
   */
  wfmShiftCategoryCreated(
    options?: EventOptions,
  ) {
    return this.track(new WfmShiftCategoryCreated(), options);
  }

  /**
   * WFM Shift Category Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Shift%20Category%20Deleted)
   *
   * When a user hits the trash icon next to a shift category name in manage shifts
   *
   * @param options Amplitude event options.
   */
  wfmShiftCategoryDeleted(
    options?: EventOptions,
  ) {
    return this.track(new WfmShiftCategoryDeleted(), options);
  }

  /**
   * WFM Shift Category Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Shift%20Category%20Edited)
   *
   * When a user clicks the pencil icon next to a shift category within the slideout for manage shifts
   *
   * @param options Amplitude event options.
   */
  wfmShiftCategoryEdited(
    options?: EventOptions,
  ) {
    return this.track(new WfmShiftCategoryEdited(), options);
  }

  /**
   * WFM Shift Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Shift%20Created)
   *
   * When a user is in the purple 'manage shifts' button slideout, and clicks to create a child shift
   *
   * @param options Amplitude event options.
   */
  wfmShiftCreated(
    options?: EventOptions,
  ) {
    return this.track(new WfmShiftCreated(), options);
  }

  /**
   * WFM Shift Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Shift%20Deleted)
   *
   * When a user clicks the trash icon in a shift drop down in manage shifts
   *
   * @param options Amplitude event options.
   */
  wfmShiftDeleted(
    options?: EventOptions,
  ) {
    return this.track(new WfmShiftDeleted(), options);
  }

  /**
   * WFM Shift Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Shift%20Edited)
   *
   * When a user changes the details of a shift (start/end time, name, or break minutes) from either the shift drop down in the manage shifts slideout or the pencil icon in a work area date slideout
   *
   * @param properties The event's properties (e.g. Edited Via)
   * @param options Amplitude event options.
   */
  wfmShiftEdited(
    properties?: WfmShiftEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmShiftEdited(properties), options);
  }

  /**
   * WFM Summary Tab Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Summary%20Tab%20Viewed)
   *
   * When a WFM user views the WFM summary tab
   *
   * @param options Amplitude event options.
   */
  wfmSummaryTabViewed(
    options?: EventOptions,
  ) {
    return this.track(new WfmSummaryTabViewed(), options);
  }

  /**
   * WFM Summary View Toggled
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Summary%20View%20Toggled)
   *
   * **Indicates that the user clicks the toggle for week/day view to help understand day view usage**
   *
   * @param options Amplitude event options.
   */
  wfmSummaryViewToggled(
    options?: EventOptions,
  ) {
    return this.track(new WfmSummaryViewToggled(), options);
  }

  /**
   * WFM Timekeeping Applied
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Timekeeping%20Applied)
   *
   * When a user clicks on a scheduled employee to apply an unexcused timekeeping event
   *
   * @param properties The event's properties (e.g. Absence Type)
   * @param options Amplitude event options.
   */
  wfmTimekeepingApplied(
    properties: WfmTimekeepingAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmTimekeepingApplied(properties), options);
  }

  /**
   * WFM VTO Applied
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20VTO%20Applied)
   *
   * **Indicates VTO applied to employee and from day or week view**
   *
   * @param properties The event's properties (e.g. Employee Name)
   * @param options Amplitude event options.
   */
  wfmVtoApplied(
    properties: WfmVtoAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WfmVtoApplied(properties), options);
  }

  /**
   * WFM Work Area Date Card Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/WFM%20Work%20Area%20Date%20Card%20Clicked)
   *
   * When a user clicks on any work area date card to open the slideout from the summary page
   *
   * @param options Amplitude event options.
   */
  wfmWorkAreaDateCardClicked(
    options?: EventOptions,
  ) {
    return this.track(new WfmWorkAreaDateCardClicked(), options);
  }

  /**
   * Withdrawn Bids Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Withdrawn%20Bids%20Viewed)
   *
   * When a User clicks on the Bid Withdrawn tab in the Bids table on the Op Details page
   *
   * @param options Amplitude event options.
   */
  withdrawnBidsViewed(
    options?: EventOptions,
  ) {
    return this.track(new WithdrawnBidsViewed(), options);
  }

  /**
   * Work Area Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Work%20Area%20Added)
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Added Via)
   * @param options Amplitude event options.
   */
  workAreaAdded(
    properties: WorkAreaAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkAreaAdded(properties), options);
  }

  /**
   * Work Area Collapsed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Work%20Area%20Collapsed)
   *
   * When a User collapses a work area within the Ops tab
   *
   * @param options Amplitude event options.
   */
  workAreaCollapsed(
    options?: EventOptions,
  ) {
    return this.track(new WorkAreaCollapsed(), options);
  }

  /**
   * Work Area Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Work%20Area%20Deleted)
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Deleted Via)
   * @param options Amplitude event options.
   */
  workAreaDeleted(
    properties: WorkAreaDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkAreaDeleted(properties), options);
  }

  /**
   * Work Area Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Work%20Area%20Edited)
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Department)
   * @param options Amplitude event options.
   */
  workAreaEdited(
    properties: WorkAreaEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkAreaEdited(properties), options);
  }

  /**
   * Work Area Expanded
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Work%20Area%20Expanded)
   *
   * When a User expands a work area within the Ops tab
   *
   * @param options Amplitude event options.
   */
  workAreaExpanded(
    options?: EventOptions,
  ) {
    return this.track(new WorkAreaExpanded(), options);
  }

  /**
   * Work Area Gate Passed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Work%20Area%20Gate%20Passed)
   *
   * Passed second step of Gated Onboarding
   *
   * Owner: Laura Acevedo Reyes
   *
   * @param properties The event's properties (e.g. Number of Work Areas)
   * @param options Amplitude event options.
   */
  workAreaGatePassed(
    properties: WorkAreaGatePassedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WorkAreaGatePassed(properties), options);
  }

  /**
   * Ylp Bids Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Ylp%20Bids%20Viewed)
   *
   * When a User clicks on the YLP tab in the Bids table on the Op Details page
   *
   * @param options Amplitude event options.
   */
  ylpBidsViewed(
    options?: EventOptions,
  ) {
    return this.track(new YlpBidsViewed(), options);
  }

  /**
   * Ylp Cleanup Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Ylp%20Cleanup%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  ylpCleanupButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new YlpCleanupButtonClicked(), options);
  }

  /**
   * Ylp Cleanup Filters Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/veryableops/Marketplace/events/main/latest/Ylp%20Cleanup%20Filters%20Updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Last Active)
   * @param options Amplitude event options.
   */
  ylpCleanupFiltersUpdated(
    properties?: YlpCleanupFiltersUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new YlpCleanupFiltersUpdated(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
